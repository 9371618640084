/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useRef, useState} from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useStore } from 'effector-react';
import Modal, { IModalRef } from 'components/helpers/modals/Modal';
import {ICompanyCard, IFormikRef, IRegisterUserParams} from 'types';
import UserForm from './UserForm';
import { registerUserFx } from '../../../models/users';
import CompanyCardEditForm from "../../features/company-card/CompanyCardEditForm";

interface ICreateUserModalProps {
	fetchUsersList: () => void;
}

const CreateUserModal = ({ fetchUsersList }: ICreateUserModalProps): React.ReactElement => {
	const [mdDone, setMdDone] = useState(false);
	const [sdDone, setSdDone] = useState(false);
	const [data, setData] = useState({});
	const registerUserPending = useStore(registerUserFx.pending);

	const modalRef = useRef<IModalRef>(null);
	const formikRef1 = useRef<IFormikRef>(null);
	const formikRef2 = useRef<IFormikRef>(null);

	const handleModalOpen = () => {
		if (modalRef.current) modalRef.current.handleModalOpen();
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const submitFunc1 = async (vals: any) => {
		setData((d) => ({...d, ...vals}));
		setMdDone(true);
	}

	const submitFunc2 = async (vals: any) => {
		const redacted = {...vals, typeCC: vals.type};
		delete redacted.type;
		setData((d) => ({...d, ...redacted}));
		setSdDone(true);
	}

	const submitFunc = async () => {
		await registerUserFx(data as IRegisterUserParams);
		if (formikRef1.current) formikRef1.current.resetForm();
		if (formikRef2.current) formikRef2.current.resetForm();
		handleModalClose();
		await fetchUsersList();
	};

	const handleSubmit = async () => {
		if (formikRef1.current) formikRef1.current.handleSubmit();
		if (formikRef2.current) formikRef2.current.handleSubmit();
		await submitFunc();
	}

	const modalContent = {
		toggleChildren: (
			<Button size='large' variant='contained' onClick={handleModalOpen}>
				Создать пользователя
			</Button>
		),
		contentChildren: (
			<Stack spacing={2}>
				<Stack>
					<Typography align='center' variant='h4'>
						Создать пользователя
					</Typography>
				</Stack>
				<UserForm ref={formikRef1} submitFunc={submitFunc1} />
				<CompanyCardEditForm
					ref={formikRef2}
					submitFunc={submitFunc2}
					companyCard={{} as ICompanyCard}
					disabled={false}
				/>
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' spacing={1} sx={{ mt: 1, width: '100%' }}>
				<Button
					fullWidth
					size='large'
					variant='contained'
					color='secondary'
					onClick={handleModalClose}
				>
					Отменить
				</Button>
				<LoadingButton
					loading={registerUserPending}
					fullWidth
					size='large'
					variant='contained'
					onClick={handleSubmit}
				>
					Создать
				</LoadingButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
		/>
	);
};

export default CreateUserModal;
