/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

// shared
export const DOWNLOAD_FILE_ERROR: string = 'Не удалось скачать файл, повторите попытку позже.';

// auth
export const LOGIN_ERROR: string =
	'Не удалось войти в систему, проверьте правильность введенных данных.';
export const FETCH_CURRENT_USER_ERROR: string =
	'Не удалось получить информацию об авторизованном пользователе, повторите попытку позже.';

// issues
export const FETCH_ISSUES_LIST_ERROR: string =
	'Не удалось получить список обсуждений, повторите попытку позже.';
export const FETCH_RESPONSES_LIST_ERROR: string =
	'Не удалось получить список сообщений, повторите попытку позже.';
export const ADD_RESPONSE_TO_ISSUE_ERROR: string =
	'Не удалось отправить сообщение, повторите попытку позже.';
export const ADD_RESPONSE_FILE_TO_ISSUE_ERROR: string =
	'Не удалось отправить файл, повторите попытку позже.';

// users
export const FETCH_USERS_LIST_ERROR: string =
	'Не удалось получить список пользователей, повторите попытку позже.';
export const REGISTER_USER_ERROR: string =
	'Не удалось создать пользователя, повторите попытку позже.';
export const PATCH_USER_ERROR: string =
	'Не удалось обновить данные пользователя, повторите попытку позже.';

// companyCard
export const FETCH_USER_COMPANY_CARD_ERROR: string =
	'Не удалось получить карточку компании пользователя, повторите попытку позже.';
export const PATCH_USER_COMPANY_CARD_ERROR: string =
	'Не удалось обновить карточку компании пользователя, повторите попытку позже.';
export const ADD_SLOT_TO_COMPANY_CARD_ERROR: string =
	'Не удалось добавить слот в карточку компании пользователя, повторите попытку позже.';
export const PATCH_SLOT_TITLE_COMPANY_CARD_ERROR: string =
	'Не удалось изменить название слота карточки компании пользователя, повторите попытку позже.';
export const DELETE_SLOT_TITLE_COMPANY_CARD_ERROR: string =
	'Не удалось удалить слот карточки компании, возможно в слоте есть файл.';
