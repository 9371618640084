/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
	Box,
	Drawer,
	useMediaQuery,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	useTheme,
} from '@mui/material';
import CustomScrollbar from 'components/helpers/other/CustomScrollbar';
import { SidebarWidth } from 'shared/variables';
import SidebarConfig from './SidebarConfig';
import Logo from '../logo/Logo';

interface ISidebarProps {
	isSidebarOpen: boolean;
	isMobileSidebarOpen: boolean;
	onSidebarClose: () => void;
}

const Sidebar = ({ isMobileSidebarOpen, onSidebarClose, isSidebarOpen }: ISidebarProps) => {
	const theme = useTheme();
	const { pathname } = useLocation();

	const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

	const [open, setOpen] = useState<boolean | number>(true);

	const handleClick = (index: number) => {
		if (open === index) {
			setOpen(prevopen => !prevopen);
		} else {
			setOpen(index);
		}
	};

	const SidebarContent = (
		<CustomScrollbar style={{ height: 'calc(100vh - 5px)' }}>
			<Box sx={{ p: 2 }}>
				<Logo />
				<Box>
					<List>
						{SidebarConfig.map((item, index) => (
							<List component='li' disablePadding key={item.title}>
								<ListItem
									onClick={() => handleClick(index)}
									button
									component={NavLink}
									to={item.href}
									selected={pathname === item.href}
									sx={{
										mb: 1,
										...(pathname === item.href && {
											color: 'white',
											backgroundColor: themeLocal =>
												`${themeLocal.palette.primary.main}!important`,
										}),
									}}
								>
									<ListItemIcon
										sx={{
											...(pathname === item.href && { color: 'white' }),
										}}
									>
										{item.icon}
									</ListItemIcon>
									<ListItemText onClick={onSidebarClose} sx={{ whiteSpace: 'nowrap' }}>
										{item.title}
									</ListItemText>
								</ListItem>
							</List>
						))}
					</List>
				</Box>
			</Box>
		</CustomScrollbar>
	);
	if (lgUp) {
		return (
			<Drawer
				anchor='left'
				open={isSidebarOpen}
				variant='persistent'
				PaperProps={{
					sx: {
						width: SidebarWidth,
						border: '0 !important',
						boxShadow: '0px 7px 30px 0px rgb(113 122 131 / 11%)',
					},
				}}
			>
				{SidebarContent}
			</Drawer>
		);
	}
	return (
		<Drawer
			anchor='left'
			open={isMobileSidebarOpen}
			onClose={onSidebarClose}
			PaperProps={{
				sx: {
					width: SidebarWidth,
					border: '0 !important',
				},
			}}
			variant='temporary'
		>
			{SidebarContent}
		</Drawer>
	);
};

export default Sidebar;
