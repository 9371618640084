/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useState } from 'react';
import { Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Variant } from '@mui/material/styles/createTypography';

interface INamePopoverProps {
	name: string;
	variant?: Variant;
	sx?: SxProps;
}

const NamePopover = ({ name, variant, sx }: INamePopoverProps): React.ReactElement => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
		setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<Stack>
				<Typography
					id='name-popover-text'
					variant={variant}
					noWrap
					onClick={handleOpen}
					sx={{
						py: 0.2,
						px: 1,
						borderRadius: 1,
						':hover': { cursor: 'pointer', backgroundColor: 'rgba(126,214,255,0.15)' },
						pointerEvents: 'auto',
						...sx,
					}}
				>
					{name}
				</Typography>
			</Stack>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<Typography sx={{ p: 2 }}>{name}</Typography>
			</Popover>
		</>
	);
};

NamePopover.defaultProps = {
	variant: 'h6',
	sx: {},
};

export default NamePopover;
