/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import ThemeConfig from 'theme';
import Router from 'routes/Router';
import ScrollToTop from 'components/helpers/other/ScrollToTop';
import ErrorSnackbar from 'components/helpers/snackbars/ErrorSnackbar';
import SuccessSnackbar from 'components/helpers/snackbars/SuccessSnackbar';
import Chat from './components/features/chat/Chat';

const App = (): React.ReactElement => (
	<ThemeConfig>
		<ScrollToTop />
		<ErrorSnackbar />
		<SuccessSnackbar />
		<Chat />
		<Router />
	</ThemeConfig>
);

export default App;
