/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Button, Divider, Stack, Tooltip, Typography} from '@mui/material';
import { DateTime } from 'luxon';
import fileIcon from '@iconify/icons-eva/file-fill';
import { Icon } from '@iconify/react';
import { IIssueWithExpandUsers, IResponsesListItem } from '../../../types';
import { chatMessageItemColorPalette } from '../../../shared/content/chat';
import { downloadFile, formatBytes } from '../../../shared/functions';
import { downloadResponseFileFx } from '../../../models/issues';
import CompanyCardModal from '../company-card/CompanyCardModal';

interface IMessageItemProps {
	chatInfo: IIssueWithExpandUsers;
	item: IResponsesListItem;
}

const MessageItem = ({ chatInfo, item }: IMessageItemProps): React.ReactElement => {
	const getInitials = () => item.author.name.slice(0, 2).toUpperCase();

	const downloadResponseFile = async () => {
		if ('file' in item) {
			const blob = await downloadResponseFileFx({
				issueId: chatInfo.id,
				responseId: item.id,
				fileId: item.file._id,
			});
			downloadFile(blob, item.file.filename);
		}
	};

	return (
		<Stack direction='row' spacing={0.8}>
			<Stack>
				<Box
					sx={{
						width: 30,
						height: 30,
						backgroundColor:
							item.author.id === chatInfo.author.id
								? chatMessageItemColorPalette[4]
								: chatMessageItemColorPalette[0],
						borderRadius: '50%',
						display: 'grid',
						placeItems: 'center',
					}}
				>
					<Typography variant='caption' sx={{ lineHeight: 1, color: '#ffffff' }}>
						<strong>{getInitials()}</strong>
					</Typography>
				</Box>
			</Stack>
			<Stack sx={{ width: '100%' }} spacing={0.1}>
				<Stack direction='row' sx={{ justifyContent: 'space-between' }}>
					<Typography
						variant='caption'
						sx={{
							lineHeight: 1.2,
							color:
								item.author.id === chatInfo.author.id
									? chatMessageItemColorPalette[4]
									: chatMessageItemColorPalette[0],
						}}
					>
						{item.author.name}
					</Typography>
					<Typography variant='caption' sx={{ lineHeight: 1.2 }}>
						{DateTime.fromISO(item.createdAt).toLocaleString(DateTime.TIME_24_SIMPLE)}
					</Typography>
				</Stack>
				<Stack sx={{ pr: 1 }}>
					{
						// prettier-ignore
						(item.type === 'text' && 'text' in item)
						&& <Typography variant='body1'>{item.text}</Typography>
					}
					{
						// prettier-ignore
						(item.type === 'companyCard' && 'companyCard' in item)
						&& (
							<Stack>
								<CompanyCardModal
									chatInfo={chatInfo}
									responseItem={item}
								/>
								<Stack>
									<Typography variant='body1'>{item.text}</Typography>
								</Stack>
							</Stack>
						)
					}
					{
						// prettier-ignore
						(item.type === 'file' && 'file' in item)
						&& (
							<Stack>
								<Stack direction='row' sx={{ my: 0.3 }}>
									<Box
										sx={{
											width: 30,
											height: 30,
											backgroundColor: '#0088cc',
											borderRadius: '50%',
											display: 'grid',
											placeItems: 'center',
											mr: 0.5,
										}}
									>
										<Icon icon={fileIcon} width={18} height={18} />
									</Box>
									<Stack>
										<Typography variant='body2' sx={{ fontSize: '14px', lineHeight: 1.3 }}>
											{item.file.filename}
										</Typography>
										<Stack direction='row' sx={{ alignItems: 'center' }}>
											<Typography
												variant='caption'
												sx={{ fontSize: '11px', lineHeight: 1.3 }}
											>
												{formatBytes(item.file.length)}
											</Typography>
											<Box
												sx={{
													width: '6px',
													height: '1px',
													mx: '5px',
													mt: '-1px',
													backgroundColor: 'white',
												}}
											/>
											<Button
												disableRipple
												sx={{
													p: 0,
													minWidth: 0,
													width: 'fit-content',
													fontSize: '11px',
													lineHeight: 1.3,
													':hover': {
														backgroundColor: 'transparent',
														textDecoration: 'underline',
													},
												}}
												onClick={downloadResponseFile}
											>
												Скачать
											</Button>
										</Stack>
									</Stack>
								</Stack>
								<Stack>
									<Typography variant='body1'>{item.file.metadata.description}</Typography>
								</Stack>
							</Stack>
						)
					}
					{
						// prettier-ignore
						(item.type === 'signedFile' && 'file' in item)
						&& (
							<Stack>
								<Stack direction='row' sx={{my: 0.3}}>
									<Box
										sx={{
											width: 30,
											height: 30,
											backgroundColor: '#0088cc',
											borderRadius: '50%',
											display: 'grid',
											placeItems: 'center',
											mr: 0.5,
										}}
									>
										<Icon icon={fileIcon} width={18} height={18}/>
									</Box>
									<Stack sx={{maxWidth: '35%'}}>
										<Tooltip sx={{maxWidth: 'none'}} title={item.file.filename} arrow>
											<Typography variant='body2' sx={{
												overflow: 'hidden',
												textOverflow: 'ellipsis', fontSize: '1rem', lineHeight: 1.3
											}}>
												{item.file.filename}
											</Typography>
										</Tooltip>
										<Stack direction='row' sx={{alignItems: 'center'}}>
											<Typography
												variant='caption'
												sx={{fontSize: '0.75rem', lineHeight: 1.3}}
											>
												{formatBytes(item.file.length)}
											</Typography>
											<Box
												sx={{
													width: '6px',
													height: '1px',
													mx: '5px',
													mt: '-1px',
													backgroundColor: 'white',
												}}
											/>
										</Stack>
									</Stack>
								</Stack>
								<Divider/>
								<Stack direction='row' display='flex' spacing={1} sx={{mt: 1, mb: 1}}>
									<Button
										disableRipple
										sx={{
											p: 0,
											minWidth: 0,
											width: 'fit-content',
											fontSize: '11px',
											lineHeight: 1.3,
											':hover': {
												backgroundColor: 'transparent',
												textDecoration: 'underline',
											},
										}}
										onClick={downloadResponseFile}
									>
										Скачать
									</Button>
								</Stack>
								<Stack sx={{mt: 0.5}}>
									<Typography sx={{fontSize: '1rem'}}>{item.file.metadata.description}</Typography>
									<Stack sx={{
										display: 'flex',
										p: 0.5,
										mb: 1,
										mt: 0.5,
										borderRadius: '5px',
										borderStyle: 'solid',
										borderWidth: '1px',
									}} direction='row'>
										<Stack sx={{flex: 1}}>
											<Typography sx={{fontSize: '1rem'}} textAlign='center'>
												{item.author.name}
											</Typography>
											<Divider sx={{m: 0.5, borderColor: 'text.primary'}}/>
											<Typography sx={{fontSize: '1rem', color: item.signedByAuthor ? 'success.main' : 'error.main'}} textAlign='center'>
												{item.signedByAuthor ? 'Подписано' : 'Не подписано'}
											</Typography>
										</Stack>
										<Divider sx={{mr: 1, ml: 1, borderColor: 'text.primary'}} orientation='vertical'/>
										<Stack sx={{flex: 1}}>
											<Typography sx={{fontSize: '1rem'}} textAlign='center'>
												{item.author.id === chatInfo.author.id ? chatInfo.participant.name : chatInfo.author.name}
											</Typography>
											<Divider sx={{m: 0.5, borderColor: 'text.primary'}}/>
											<Typography sx={{fontSize: '1rem', color: item.signedByParticipant ? 'success.main' : 'error.main'}} textAlign='center'>
												{item.signedByParticipant ? 'Подписано' : 'Не подписано'}
											</Typography>
										</Stack>
									</Stack>
								</Stack>
							</Stack>
						)
					}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default MessageItem;
