/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import GrowTransition from 'components/helpers/transitions/GrowTransition';

export interface IModalRef {
	handleModalOpen: () => void;
	handleModalClose: () => void;
}

interface IModalProps {
	toggleChildren: React.ReactElement;
	contentChildren: React.ReactElement;
	actionsChildren: React.ReactElement;
}

const Modal = forwardRef(
	({ toggleChildren, contentChildren, actionsChildren }: IModalProps, ref) => {
		const [modalOpen, setModalOpen] = useState(false);

		const handleModalOpen = () => setModalOpen(true);
		const handleModalClose = () => setModalOpen(false);

		useImperativeHandle(ref, () => ({
			handleModalOpen: () => handleModalOpen(),
			handleModalClose: () => handleModalClose(),
		}));

		return (
			<>
				{toggleChildren}
				<Dialog
					PaperProps={{ sx: { width: '50vw' } }}
					onBackdropClick={handleModalClose}
					TransitionComponent={GrowTransition}
					open={modalOpen}
				>
					<DialogContent>{contentChildren}</DialogContent>
					<DialogActions sx={{ pt: 0 }}>{actionsChildren}</DialogActions>
				</Dialog>
			</>
		);
	}
);

export default Modal;
