/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

const pxToRem = (value: number) => `${value / 16}rem`;

// interface responsiveFontSizesSettings {
// 	xs: number;
// 	sm: number;
// 	md: number;
// 	lg: number;
// }

// function responsiveFontSizes({ xs, sm, md, lg }: responsiveFontSizesSettings) {
// 	return {
// 		'@media (min-width:0px)': {
// 			fontSize: pxToRem(xs),
// 		},
// 		'@media (min-width:600px)': {
// 			fontSize: pxToRem(sm),
// 		},
// 		'@media (min-width:960px)': {
// 			fontSize: pxToRem(md),
// 		},
// 		'@media (min-width:1280px)': {
// 			fontSize: pxToRem(lg),
// 		},
// 	};
// }

const FONT_PRIMARY = 'DM Sans, sans-serif';

const typography = {
	fontFamily: FONT_PRIMARY,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightBold: 700,
	h1: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(30),
		// ...responsiveFontSizes({ xs: 32, sm: 40, md: 64, lg: 68 }),
	},
	h2: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(24),
		// ...responsiveFontSizes({ xs: 18, sm: 28, md: 42, lg: 44 }),
	},
	h3: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(21),
		// ...responsiveFontSizes({ xs: 20, sm: 22, md: 26, lg: 30 }),
	},
	h4: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(18),
		// ...responsiveFontSizes({ xs: 16, sm: 20, md: 22, lg: 24 }),
	},
	h5: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
		// ...responsiveFontSizes({ xs: 16, sm: 20, md: 22, lg: 24 }),
	},
	h6: {
		fontWeight: 500,
		lineHeight: 1.5,
		fontSize: pxToRem(14),
		// ...responsiveFontSizes({ xs: 16, sm: 20, md: 22, lg: 24 }),
	},
	body1: {
		fontWeight: 400,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
		// ...responsiveFontSizes({ xs: 16, sm: 18, md: 20, lg: 22 }),
	},
	subtitle1: {
		fontWeight: 400,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
	},
	caption: {
		fontWeight: 400,
		lineHeight: 1.5,
		fontSize: pxToRem(14),
	},
	button: {
		textTransform: 'none',
		fontWeight: 400,
	},
};

export default typography;
