/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Stack,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import { useStore } from 'effector-react';
import communityIcon from '@iconify-icons/gg/community';
import fileIcon from '@iconify/icons-eva/file-fill';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { GetIcon } from '../../helpers/other/GetIcon';
import {
	ICompanyCard,
	IFormikRef,
	IIssueWithExpandUsers,
	ILongUser,
	IPatchUserCompanyCardParams,
	IResponsesListItem,
} from '../../../types';
import { TabPanel } from '../../helpers/other/Tabs';
import GrowTransition from '../../helpers/transitions/GrowTransition';
import { fetchUserCompanyCardFx, patchUserCompanyCardFx } from '../../../models/users';
import Spinner from '../../helpers/other/Spinner';
import CompanyCardEditForm from './CompanyCardEditForm';
import SlotsTab from './slots/SlotsTab';
import SlotFormModal from './slots/SlotFormModal';

interface ICompanyCardModalProps {
	row?: ILongUser;
	chatInfo?: IIssueWithExpandUsers;
	responseItem?: IResponsesListItem;
}

const CompanyCardModal = ({
	row,
	chatInfo,
	responseItem,
}: ICompanyCardModalProps): React.ReactElement => {
	const fetchUserCompanyCardPending = useStore(fetchUserCompanyCardFx.pending);
	const patchUserCompanyCardPending = useStore(patchUserCompanyCardFx.pending);

	const [modalOpen, setModalOpen] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [companyCard, setCompanyCard] = useState<null | ICompanyCard>(null);

	const formikRef = useRef<IFormikRef>(null);

	const handleModalOpen = () => setModalOpen(true);
	const handleModalClose = () => setModalOpen(false);

	const fetchUserCompanyCard = async () => {
		if (row) {
			const response = await fetchUserCompanyCardFx({ userId: row.id });
			setCompanyCard(response);
		}
	};

	const submitFuncCompanyCardEditForm = async (data: IPatchUserCompanyCardParams) => {
		if (row) {
			await patchUserCompanyCardFx({ userId: row.id, data });
			await fetchUserCompanyCard();
		}
	};

	const handleSubmitCompanyCardEditForm = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
		handleModalClose();
	};

	useEffect(() => {
		if (modalOpen) {
			setTabIndex(0);
			if (row) fetchUserCompanyCard().then();
			else if (responseItem && 'companyCard' in responseItem)
				setCompanyCard(responseItem.companyCard);
		}
	}, [modalOpen]);

	return (
		<>
			{row ? (
				<Tooltip title='Карточка компании' placement='top' arrow>
					<IconButton color='inherit' onClick={handleModalOpen}>
						{GetIcon(communityIcon)}
					</IconButton>
				</Tooltip>
			) : (
				<Stack direction='row' sx={{ my: 0.3 }}>
					<Box
						sx={{
							width: 30,
							height: 30,
							backgroundColor: '#8cd3ff',
							borderRadius: '50%',
							display: 'grid',
							placeItems: 'center',
							mr: 0.5,
						}}
					>
						<Icon icon={fileIcon} width={18} height={18} />
					</Box>
					<Stack>
						<Typography variant='body2' sx={{ fontSize: '14px', lineHeight: 1.3 }}>
							Карточка компании
						</Typography>
						<Button
							disableRipple
							sx={{
								p: 0,
								minWidth: 0,
								width: 'fit-content',
								fontSize: '11px',
								lineHeight: 1.3,
								':hover': {
									backgroundColor: 'transparent',
									textDecoration: 'underline',
								},
							}}
							onClick={handleModalOpen}
						>
							Открыть
						</Button>
					</Stack>
				</Stack>
			)}
			<Dialog
				PaperProps={{ sx: { width: '50vw', position: 'absolute', top: 0 } }}
				onBackdropClick={handleModalClose}
				TransitionComponent={GrowTransition}
				open={modalOpen}
			>
				{!companyCard || fetchUserCompanyCardPending ? (
					<Spinner size='3rem' sx={{ height: '100%' }} />
				) : (
					<>
						<DialogContent sx={{ pt: 2 }}>
							<Stack spacing={2}>
								<Stack
									sx={{ borderBottom: 1, borderColor: 'divider', alignItems: 'center' }}
								>
									<Tabs
										value={tabIndex}
										onChange={(event: React.SyntheticEvent, newValue: number) =>
											setTabIndex(newValue)
										}
									>
										<Tab label='Данные карточки' />
										<Tab label='Документы карточки' />
									</Tabs>
								</Stack>
								<TabPanel value={tabIndex} index={0} sx={{ pt: 1 }}>
									<CompanyCardEditForm
										ref={formikRef}
										submitFunc={submitFuncCompanyCardEditForm}
										companyCard={companyCard}
										disabled={!row}
									/>
								</TabPanel>
								<TabPanel value={tabIndex} index={1}>
									<SlotsTab
										user={row}
										companyCard={companyCard}
										fetchUserCompanyCard={fetchUserCompanyCard}
										chatInfo={chatInfo}
										responseItem={responseItem}
									/>
								</TabPanel>
							</Stack>
						</DialogContent>
						<DialogActions sx={{ pt: 0 }}>
							<Stack direction='row' spacing={1} sx={{ mt: 1, width: '100%' }}>
								<Button
									fullWidth
									size='large'
									variant='contained'
									color='secondary'
									onClick={handleModalClose}
								>
									Отменить
								</Button>
								{row && !tabIndex && (
									<LoadingButton
										fullWidth
										loading={patchUserCompanyCardPending}
										size='large'
										variant='contained'
										onClick={handleSubmitCompanyCardEditForm}
									>
										Изменить
									</LoadingButton>
								)}
								{row && !!tabIndex && (
									<SlotFormModal user={row} fetchUserCompanyCard={fetchUserCompanyCard} />
								)}
							</Stack>
						</DialogActions>
					</>
				)}
			</Dialog>
		</>
	);
};

export default CompanyCardModal;
