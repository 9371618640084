/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Dialog, DialogContent, Divider, IconButton, Stack, Tooltip, Typography,} from '@mui/material';
import attachmentIcon from '@iconify-icons/gg/attachment';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {Form, FormikProvider, useFormik} from 'formik';
import {Icon} from '@iconify/react';
import fileIcon from '@iconify/icons-eva/file-fill';
import {useStore} from "effector-react";
import {createEvent, createStore} from "effector";
import GrowTransition from '../../helpers/transitions/GrowTransition';
import {GetIcon} from '../../helpers/other/GetIcon';
import {addResponseFileToIssueFx, completeIssueFx} from '../../../models/issues';
import {IIssueWithExpandUsers} from '../../../types';
import {formatBytes} from '../../../shared/functions';

interface IAddAttachmentModal {
	chatInfo: IIssueWithExpandUsers;
	fetchResponsesList: () => void;
}

export const setOpenCompleteIssueModal = createEvent<{ open: boolean }>();
export const $completeIssueModal = createStore({
	open: false,
}).on(setOpenCompleteIssueModal, (state, payload) => ({...state, ...payload}));

export const handleCompleteIssueModalOpen = () => setOpenCompleteIssueModal({
	open: true,
});
export const handleCompleteIssueModalClose = () =>
	setOpenCompleteIssueModal({open: false});

const CompleteIssueModal = ({
	                            chatInfo,
	                            fetchResponsesList,
                            }: IAddAttachmentModal): React.ReactElement => {
	const {open: modalOpen} = useStore($completeIssueModal);

	const formik = useFormik<{ file: null | File; description: string }>({
		initialValues: {
			file: null,
			description: '',
		},
		onSubmit: async ({file, description}) => {
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				formData.append('description', description);
				await completeIssueFx({issueId: chatInfo.id, data: formData});
				fetchResponsesList();
				handleCompleteIssueModalClose();
			}
		},
	});

	const {handleSubmit, values, getFieldProps, setFieldValue} = formik;

	return (
		<Dialog
			PaperProps={{sx: {width: '400px'}}}
			onBackdropClick={handleCompleteIssueModalClose}
			TransitionComponent={GrowTransition}
			open={modalOpen}
		>
			<DialogContent sx={{p: 0, position: 'relative'}}>
				<FormikProvider value={formik}>
					<Form
						autoComplete='off'
						noValidate
						onSubmit={handleSubmit}
						style={{height: '100%'}}
					>
						<Stack
							sx={{
								pt: 2,
								pb: 1,
								justifyContent: 'space-between',
							}}
						>
							<Stack sx={{pl: 2.5, pr: 1}}>
								<label style={{width: 'fit-content'}} htmlFor='import-button-file2'>
									<input
										style={{display: 'none'}}
										id='import-button-file2'
										type='file'
										accept='.docx,.doc'
										onChange={event => {
											if (event.currentTarget.files) {
												const file = event.currentTarget.files[0];
												setFieldValue('file', file);
											}
										}}
									/>
									<Tooltip title='Добавить файл' placement='top' arrow>
										<IconButton color='inherit' component='span'>
											{GetIcon(attachmentIcon)}
										</IconButton>
									</Tooltip>
								</label>
								{!!values.file && <Stack direction='row' sx={{my: 0.3, alignItems: 'center'}}>
									<Box
										sx={{
											width: 40,
											height: 40,
											backgroundColor: '#0088cc',
											borderRadius: '50%',
											display: 'grid',
											placeItems: 'center',
											mr: 1,
										}}
									>
										<Icon icon={fileIcon} width={24} height={24}/>
									</Box>
									<Stack>
										<Typography
											variant='body1'>{values.file ? values.file.name : 'Файл не выбран'}</Typography>
										<Typography
											variant='caption'
											sx={{fontSize: '13px', lineHeight: 1.3}}
										>
											{values.file ? formatBytes(values.file.size) : ''}
										</Typography>
									</Stack>
								</Stack>}
							</Stack>
							<Divider sx={{mt: 2, mb: 0.5}}/>
							<Stack
								direction='row'
								sx={{
									height: '100%',
									alignItems: 'center',
									pl: 2.5,
									pr: 1,
								}}
							>
								<input
									{...getFieldProps('description')}
									placeholder='Сообщение...'
									style={{
										width: '100%',
										height: '100%',
										marginRight: '5px',
										backgroundColor: 'transparent',
										outline: 'none',
										border: 'none',
										color: 'inherit',
										fontSize: '16px',
									}}
								/>
								<Stack>
									<Tooltip title='Отправить' placement='top' arrow>
										<IconButton color='inherit' type='submit'>
											{GetIcon(sendIcon)}
										</IconButton>
									</Tooltip>
								</Stack>
							</Stack>
						</Stack>
					</Form>
				</FormikProvider>
			</DialogContent>
		</Dialog>
	);
};

export default CompleteIssueModal;
