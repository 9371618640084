/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import _ from 'lodash';
import { useStore } from 'effector-react';
import { ruRU } from '@mui/material/locale';
import { $customizer } from 'models/customizer';
import typography from './Typography';
import breakpoints from './Breakpoints';
import shadows from './Shadows';
import components from './Override';
import { TopbarHeight } from '../shared/variables';

const baseTheme = {
	direction: 'ltr',
	palette: {
		primary: {
			main: '#1a97f5',
			light: '#e6f4ff',
			dark: '#1682d4',
		},
		secondary: {
			main: '#1e4db7',
			light: '#ddebff',
			dark: '#1945a2',
		},
		success: {
			main: '#00c292',
			light: '#ebfaf2',
			dark: '#00964b',
			contrastText: '#ffffff',
		},
		danger: {
			main: '#e46a76',
			light: '#fdf3f5',
		},
		info: {
			main: '#0bb2fb',
			light: '#a7e3f4',
		},
		error: {
			main: '#e46a76',
			light: '#fdf3f5',
			dark: '#e45a68',
		},
		warning: {
			main: '#fec90f',
			light: '#fff4e5',
			dark: '#dcb014',
			contrastText: '#ffffff',
		},
		text: {
			secondary: '#777e89',
			danger: '#fc4b6c',
		},
		grey: {
			A100: '#ecf0f2',
			A200: '#99abb4',
			A400: '#767e89',
			A700: '#e6f4ff',
		},
		action: {
			disabledBackground: 'rgba(73,82,88,0.12)',
			hoverOpacity: 0.02,
			hover: 'rgba(0, 0, 0, 0.03)',
		},
	},
	shape: {
		borderRadius: 5,
	},
	mixins: {
		toolbar: {
			color: '#949db2',
			'@media(min-width:1280px)': {
				minHeight: TopbarHeight,
				padding: '0 30px',
			},
			'@media(max-width:1280px)': {
				minHeight: '64px',
			},
		},
	},
	status: {
		danger: '#e53e3e',
	},
	components,
	typography,
	breakpoints: {
		values: breakpoints,
	},
	shadows,
};

interface IThemeConfigProps {
	children: React.ReactElement | React.ReactElement[];
}

const ThemeConfig = ({ children }: IThemeConfigProps): React.ReactElement => {
	const customizer = useStore($customizer);

	const baseMode = {
		palette: {
			mode: customizer.activeMode,
			background: {
				default: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
				dark: customizer.activeMode === 'dark' ? '#1c2025' : '#ffffff',
				paper: customizer.activeMode === 'dark' ? '#282C34' : '#ffffff',
			},
			text: {
				primary: customizer.activeMode === 'dark' ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
				secondary: customizer.activeMode === 'dark' ? '#adb0bb' : '#777e89',
			},
		},
	};

	const themeOptions = _.merge({}, baseTheme, baseMode);

	// @ts-ignore
	const theme = createTheme(themeOptions, ruRU);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};

export default ThemeConfig;
