/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
// @ts-ignore
import Logo from '../../../../assets/images/logo.svg';

interface ILogoIconProps {
	sx?: SxProps;
}

const LogoIcon = ({ sx }: ILogoIconProps): React.ReactElement => (
	<Box component='img' src={Logo} sx={{ width: 40, height: 40, ...sx }} />
);

export default LogoIcon;
