/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {apiRequest, IData} from './index';
import {
	IAddResponseFileToIssueParams,
	IDownloadCompanyCardSlotFileParams,
	IDownloadResponseFileParams,
	IFetchIssuesListResponse,
	IFetchResponsesListResponse, IIssueBaseResponseFullStringParams,
	IIssueFileResponseFullStringParams,
	IIssueResponseFullStringParams, IIssueWithExpandUsers,
} from '../types';
import {
	ADD_RESPONSE_FILE_TO_ISSUE_ERROR,
	ADD_RESPONSE_TO_ISSUE_ERROR,
	DOWNLOAD_FILE_ERROR,
	FETCH_ISSUES_LIST_ERROR,
	FETCH_RESPONSES_LIST_ERROR,
} from '../shared/errors';
import {IShipment} from "../pages/dashboard/Shipments";

export const fetchIssuesList = async (params: { participantId?: string }) => {
	const response = await apiRequest<IData<IFetchIssuesListResponse>>({
		url: '/api/admin/issue/list',
		method: 'GET',
		params,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_ISSUES_LIST_ERROR);
	}
	return response.data.data;
};

export const fetchFinancingIssuesList = async () => {
	const response = await apiRequest<IData<IIssueWithExpandUsers[]>>({
		url: '/api/admin/issue/financingList',
		method: 'GET',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_ISSUES_LIST_ERROR);
	}
	return response.data.data;
};

export const fetchShipmentsList = async () => {
	const response = await apiRequest<IData<IShipment[]>>({
		url: '/api/admin/issue/shipments',
		method: 'GET',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_ISSUES_LIST_ERROR);
	}
	return response.data.data;
};

export const fetchResponsesList = async ({ issueId }: { issueId: string }) => {
	const response = await apiRequest<IData<IFetchResponsesListResponse>>({
		url: `/api/admin/issue/${issueId}/responses`,
		method: 'GET',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_RESPONSES_LIST_ERROR);
	}
	return response.data.data;
};

export const downloadResponseFile = async ({
	issueId,
	responseId,
	fileId,
}: IDownloadResponseFileParams) => {
	const response = await apiRequest<Blob>({
		url: `/api/admin/issue/${issueId}/response/${responseId}/file/${fileId}`,
		method: 'GET',
		responseType: 'blob',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || DOWNLOAD_FILE_ERROR);
	}
	return response.data;
};

export const downloadCompanyCardSlotFile = async ({
	issueId,
	responseId,
	slotId,
	fileId,
}: IDownloadCompanyCardSlotFileParams) => {
	const response = await apiRequest<Blob>({
		url: `/api/admin/issue/${issueId}/response/${responseId}/slot/${slotId}/file/${fileId}`,
		method: 'GET',
		responseType: 'blob',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || DOWNLOAD_FILE_ERROR);
	}
	return response.data;
};

export const addResponseToIssue = async ({ issueId, text }: { issueId: string; text: string }) => {
	const response = await apiRequest<IData<IIssueResponseFullStringParams>>({
		url: `/api/admin/issue/${issueId}/addResponse`,
		method: 'POST',
		data: { text },
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || ADD_RESPONSE_TO_ISSUE_ERROR);
	}
	return response.data.data;
};

export const addResponseFileToIssue = async ({ issueId, data }: IAddResponseFileToIssueParams) => {
	const response = await apiRequest<IData<IIssueFileResponseFullStringParams>>({
		url: `/api/admin/issue/${issueId}/addResponseFile`,
		method: 'POST',
		data,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || ADD_RESPONSE_FILE_TO_ISSUE_ERROR);
	}
	return response.data.data;
};

export const completeIssue = async ({ issueId, data }: IAddResponseFileToIssueParams) => {
	const response = await apiRequest<IData<IIssueBaseResponseFullStringParams>>({
		url: `/api/admin/issue/${issueId}/done`,
		method: 'POST',
		data,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || ADD_RESPONSE_FILE_TO_ISSUE_ERROR);
	}
	return response.data.data;
};
