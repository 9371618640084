/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { forwardRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box } from '@mui/material';

interface IScrollbarProps {
	children: React.ReactElement;
	style?: React.CSSProperties;
}

const CustomScrollbar = forwardRef((props: IScrollbarProps, ref) => {
	const { children, ...other } = props;

	const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);

	if (isMobile) {
		return (
			<Box ref={ref} sx={{ overflowX: 'auto' }}>
				{children}
			</Box>
		);
	}

	return (
		// @ts-ignore
		<PerfectScrollbar ref={ref} {...other}>
			{children}
		</PerfectScrollbar>
	);
});

CustomScrollbar.defaultProps = {
	style: {},
};

export default CustomScrollbar;
