/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { SxProps } from '@mui/system';
import menuIcon from '@iconify-icons/gg/menu';
import { GetIcon } from 'components/helpers/other/GetIcon';
import ProfileDropdown from './ProfileDropdown';

interface IHeaderProps {
	sx?: SxProps;
	toggleSidebar: () => void;
	toggleMobileSidebar: () => void;
}

const Header = ({ sx, toggleSidebar, toggleMobileSidebar }: IHeaderProps) => (
	<AppBar sx={sx} elevation={0}>
		<Toolbar>
			<IconButton
				edge='start'
				color='inherit'
				aria-label='menu'
				onClick={toggleSidebar}
				size='large'
				sx={{
					display: {
						lg: 'flex',
						xs: 'none',
					},
				}}
			>
				{GetIcon(menuIcon)}
			</IconButton>

			<IconButton
				size='large'
				color='inherit'
				aria-label='menu'
				onClick={toggleMobileSidebar}
				sx={{
					display: {
						lg: 'none',
						xs: 'flex',
					},
				}}
			>
				{GetIcon(menuIcon)}
			</IconButton>

			<Box flexGrow={1} />

			<ProfileDropdown />
		</Toolbar>
	</AppBar>
);

export default Header;
