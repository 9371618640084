/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import userIcon from '@iconify-icons/gg/user';
import displayGridIcon from '@iconify-icons/gg/display-grid';
import { GetIcon } from 'components/helpers/other/GetIcon';

const SidebarConfig = [
	{
		title: 'Пользователи',
		href: '/users',
		icon: GetIcon(userIcon),
	},
	{
		title: 'Переписки',
		href: '/conversations',
		icon: GetIcon(displayGridIcon),
	},
	{
		title: 'Поставки',
		href: '/shipments',
		icon: GetIcon(displayGridIcon),
	},
];

export default SidebarConfig;
