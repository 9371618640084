/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { openErrorSnackbar } from 'components/helpers/snackbars/ErrorSnackbar';
import { $user, logout } from 'models/auth';
import { axios } from './axios';

export * from './auth';
export * from './users';
export * from './issues';

export interface IData<T> {
	data: T;
	notifications: any[];
}

interface IError {
	error: string;
	errors: null;
	message: string | Array<string>;
	path: string;
	stack: string;
	status: string;
	statusCode: number;
	timestamp: string;
}

export async function apiRequest<T>(
	_config: AxiosRequestConfig
): Promise<AxiosResponse<T | IError>> {
	const { token } = $user.getState();
	let cbResponse;
	const config = {
		headers: { 'Content-type': 'application/json', Authorization: '' },
		..._config,
	};
	if (token) config.headers.Authorization = `Bearer ${token}`;
	try {
		cbResponse = await axios.request<T>(config);
	} catch (err: any) {
		cbResponse = err.response;
		if (!err.response) openErrorSnackbar('Ошибка соединения с сервером, ответ не получен');
		else if (err.response.data.statusCode === 401 && token) {
			openErrorSnackbar(err.response.data.error);
			logout();
			window.history.pushState('', '', '/login');
			window.history.pushState('', '', '/login');
			window.history.go(-1);
		}
	}
	return cbResponse;
}
