/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useStore } from 'effector-react';
import editOutlineIcon from '@iconify/icons-eva/edit-outline';
import { LoadingButton } from '@mui/lab';
import { ICompanyCardSlot, ILongUser } from '../../../../types';
import GrowTransition from '../../../helpers/transitions/GrowTransition';
import { addSlotToCompanyCardFx, patchSlotTitleCompanyCardFx } from '../../../../models/users';
import { GetIcon } from '../../../helpers/other/GetIcon';

interface ISlotFormModalProps {
	user: ILongUser;
	fetchUserCompanyCard: () => void;
	slot?: ICompanyCardSlot;
}

const SlotFormModal = ({
	user,
	fetchUserCompanyCard,
	slot,
}: ISlotFormModalProps): React.ReactElement => {
	const addSlotToCompanyCardPending = useStore(addSlotToCompanyCardFx.pending);
	const patchSlotTitleCompanyCardPending = useStore(patchSlotTitleCompanyCardFx.pending);

	const [modalOpen, setModalOpen] = useState(false);

	const handleModalOpen = () => setModalOpen(true);
	const handleModalClose = () => setModalOpen(false);

	const CompanyCardEditFormSchema = Yup.object().shape({
		title: Yup.string().required('Поле является обязательным'),
	});

	const formik = useFormik({
		initialValues: {
			title: slot ? slot.title : '',
		},
		validationSchema: CompanyCardEditFormSchema,
		onSubmit: async ({ title }) => {
			if (slot) await patchSlotTitleCompanyCardFx({ userId: user.id, slotId: slot._id, title });
			else await addSlotToCompanyCardFx({ userId: user.id, title });
			handleModalClose();
			await fetchUserCompanyCard();
		},
	});

	const { errors, touched, handleSubmit, getFieldProps } = formik;

	return (
		<>
			{slot ? (
				<Tooltip title='Изменить название документа' placement='top' arrow>
					<IconButton edge='end' onClick={handleModalOpen}>
						{GetIcon(editOutlineIcon)}
					</IconButton>
				</Tooltip>
			) : (
				<Button fullWidth size='large' variant='contained' onClick={handleModalOpen}>
					Добавить документ
				</Button>
			)}
			<Dialog
				PaperProps={{ sx: { width: '50vw' } }}
				onBackdropClick={handleModalClose}
				TransitionComponent={GrowTransition}
				open={modalOpen}
			>
				<FormikProvider value={formik}>
					<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
						<DialogContent sx={{ pt: 2 }}>
							<Stack spacing={2}>
								<Stack>
									<Typography align='center' variant='h4'>
										{slot ? 'Изменить название документа' : 'Добавить документ'}
									</Typography>
								</Stack>
								<Stack>
									<TextField
										fullWidth
										label='Название'
										{...getFieldProps('title')}
										error={Boolean(touched.title && errors.title)}
										helperText={touched.title && errors.title}
									/>
								</Stack>
							</Stack>
						</DialogContent>
						<DialogActions sx={{ pt: 0 }}>
							<Stack direction='row' spacing={1} sx={{ mt: 1, width: '100%' }}>
								<Button
									fullWidth
									size='large'
									variant='contained'
									color='secondary'
									onClick={handleModalClose}
								>
									Отменить
								</Button>
								<LoadingButton
									fullWidth
									loading={
										slot ? patchSlotTitleCompanyCardPending : addSlotToCompanyCardPending
									}
									size='large'
									variant='contained'
									type='submit'
								>
									{slot ? 'Изменить' : 'Добавить'}
								</LoadingButton>
							</Stack>
						</DialogActions>
					</Form>
				</FormikProvider>
			</Dialog>
		</>
	);
};

export default SlotFormModal;
