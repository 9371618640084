/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { set, remove } from 'local-storage';
import { fetchCurrentUser, login } from 'api';
import { USER_TOKEN, USER_DATA } from 'shared/variables';
import { $user, fetchCurrentUserFx, loginFx, logout } from './index';

loginFx.use(login);
fetchCurrentUserFx.use(fetchCurrentUser);

$user
	.on(loginFx.doneData, (_, data) => {
		set(USER_TOKEN, data.token);
		set(USER_DATA, data.user);
		return {
			token: data.token,
			user: data.user,
		};
	})
	.on(fetchCurrentUserFx.doneData, (state, user) => {
		set(USER_DATA, user);
		return {
			...state,
			user,
		};
	})
	.on(logout, () => {
		remove(USER_TOKEN);
		remove(USER_DATA);
		return {
			token: null,
			user: null,
		};
	});
