/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { IAuthResponse, ILoginRequestParams, ILongUser } from 'types';
import {apiRequest, IData} from './index';
import { FETCH_CURRENT_USER_ERROR, LOGIN_ERROR } from '../shared/errors';

export const login = async (values: ILoginRequestParams) => {
	const response = await apiRequest<IAuthResponse>({
		url: '/api/admin/auth/login',
		method: 'POST',
		data: values,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || LOGIN_ERROR);
	}
	return response.data;
};

export const fetchCurrentUser = async () => {
	const response = await apiRequest<IData<ILongUser>>({
		url: '/api/admin/auth/currentUser',
		method: 'GET',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_CURRENT_USER_ERROR);
	}
	return response.data.data;
};
