/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useEffect, useMemo, useRef } from 'react';
import {Button, Divider, Stack} from '@mui/material';
import { SxProps } from '@mui/system';
import _ from 'lodash';
import { DateTime } from 'luxon';
import DateBlock from './DateBlock';
import MessageItem from './MessageItem';
import { IIssueWithExpandUsers, IResponsesListItem } from '../../../types';
import CompleteIssue, {handleCompleteIssueModalClose, handleCompleteIssueModalOpen} from "./CompleteIssue";

const groupResponsesByDate = (responses: IResponsesListItem[]) => {
	const groupedMessages = _.groupBy(responses, item =>
		DateTime.fromISO(item.createdAt).toFormat('yyyy-MM-dd')
	);
	const sortedDates = Object.keys(groupedMessages)
		.map(date => DateTime.fromISO(date))
		.sort((a, b) => a.toMillis() - b.toMillis());
	return sortedDates.map(date => groupedMessages[date.toFormat('yyyy-MM-dd')]);
};

const sortResponsesByTime = (responses: IResponsesListItem[]) =>
	responses.sort(
		(a, b) => DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis()
	);

interface IMessageListProps {
	sx?: SxProps;
	chatInfo: IIssueWithExpandUsers;
	responsesList: IResponsesListItem[];
	fetchResponsesList: () => void;
}

const MessageList = ({ sx, chatInfo, responsesList, fetchResponsesList }: IMessageListProps): React.ReactElement => {
	const refBottomLine = useRef<null | HTMLDivElement>(null);

	const transformedMessagesList = useMemo(
		() => groupResponsesByDate(responsesList),
		[responsesList]
	);

	useEffect(() => {
		if (refBottomLine && refBottomLine.current)
			refBottomLine.current?.scrollIntoView({ block: 'start' });
	}, [responsesList]);

	return (
		<Stack sx={{ p: 2, overflowY: 'scroll', ...sx }}>
			<CompleteIssue chatInfo={chatInfo} fetchResponsesList={fetchResponsesList}/>
			{transformedMessagesList.map((groupByDate, index) => (
				<React.Fragment key={index}>
					<DateBlock date={DateTime.fromISO(groupByDate[0].createdAt)} />
					<Stack spacing={1.5}>
						{sortResponsesByTime(groupByDate).map(item => (
							<MessageItem item={item} chatInfo={chatInfo} key={item.id} />
						))}
					</Stack>
				</React.Fragment>
			))}
			<div ref={refBottomLine} />
			<Divider sx={{m: 1, borderWidth: 1}}/>
			<Stack direction='column' alignItems='center' spacing={1.5}>
				{(!chatInfo.finished && (chatInfo.acknowledgmentParticipant === null)) && (
					<Button variant='outlined' onClick={handleCompleteIssueModalOpen}>Завершить диалог</Button>
				)}
			</Stack>
		</Stack>
	);
};

export default MessageList;
