/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { forwardRef } from 'react';
import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export default forwardRef(
	(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>
	) => (
		<Grow ref={ref} {...props}>
			{props.children}
		</Grow>
	)
);
