/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { merge } from 'effector';
import { openErrorSnackbar } from 'components/helpers/snackbars/ErrorSnackbar';

import './customizer/init';
import './auth/init';
import './users/init';
import './issues/init';

import { $authEffectsFailData } from './auth';
import { $issuesEffectsFailData } from './issues';
import { $usersEffectsFailData } from './users';

const $effectsFailData = merge([
	$authEffectsFailData,
	$issuesEffectsFailData,
	$usersEffectsFailData,
]);
$effectsFailData.watch(error => openErrorSnackbar(error.message));
