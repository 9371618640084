/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { createEvent, createStore } from 'effector';
import { get } from 'local-storage';
import { USER_THEME_MODE } from 'shared/variables';
import { ActiveModeEnum, ICustomizer } from './types';

export const defaultState = {
	activeMode: get(USER_THEME_MODE) || 'light',
};

export const setThemeMode = createEvent<ActiveModeEnum>();

export const $customizer = createStore<ICustomizer>(<ICustomizer>defaultState);
