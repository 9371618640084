/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $user } from 'models/auth';
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout';
import ErrorPage from 'pages/Error';
import LoginPage from 'pages/Login';
import UsersPage from 'pages/dashboard/Users';
import ConversationsPage from 'pages/dashboard/Conversations';
import Shipments from "../pages/dashboard/Shipments";

const Router = (): React.ReactElement | null => {
	const { user } = useStore($user);
	const loggedDashboardLayout = !user ? <Navigate to='/login' /> : <DashboardLayout />;

	const routes = [
		{
			path: '/login',
			children: [{ path: '/login', element: <LoginPage /> }],
		},
		{
			path: '/users',
			element: loggedDashboardLayout,
			children: [{ path: '/users', element: <UsersPage /> }],
		},
		{
			path: '/conversations',
			element: loggedDashboardLayout,
			children: [{ path: '/conversations', element: <ConversationsPage /> }],
		},
		{
			path: '/shipments',
			element: loggedDashboardLayout,
			children: [{ path: '/shipments', element: <Shipments /> }],
		},
		{
			path: '/',
			children: [
				{ path: '404', element: <ErrorPage /> },
				{
					path: '',
					element: !user ? <Navigate to='/login' /> : <Navigate to='/help' />,
				},
			],
		},
		{ path: '*', element: <Navigate to='/404' replace /> },
	];

	return useRoutes(routes);
};

export default Router;
