/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useState } from 'react';
import { Card, Stack, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../components/helpers/other/Tabs';
import ConversationsTab from '../../components/pages/conversations/ConversationsTab';

const ConversationsPage = (): React.ReactElement => {
	const [tabIndex, setTabIndex] = useState(0);
	return (
		<Stack>
			<Card sx={{ p: 3, textAlign: 'center' }}>
				<Stack sx={{ borderBottom: 1, borderColor: 'divider', alignItems: 'center' }}>
					<Tabs
						value={tabIndex}
						onChange={(event: React.SyntheticEvent, newValue: number) =>
							setTabIndex(newValue)
						}
						allowScrollButtonsMobile
						scrollButtons
					>
						<Tab label='Мои переписки' />
						<Tab label='Переписки пользователей' />
					</Tabs>
				</Stack>
				<TabPanel value={tabIndex} index={0} sx={{ px: 4, pt: 2, pb: 1 }}>
					<ConversationsTab issueTypes={['refuseNDA']} />
				</TabPanel>
				<TabPanel value={tabIndex} index={1} sx={{ pt: 2 }}>
					<ConversationsTab />
				</TabPanel>
			</Card>
		</Stack>
	);
};

export default ConversationsPage;
