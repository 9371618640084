/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useRef } from 'react';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useStore } from 'effector-react';
import editOutlineIcon from '@iconify/icons-eva/edit-outline';
import _ from 'lodash';
import {IFormikRef, ILongUser, IRegisterBasicUserParams, IRegisterUserParams} from 'types';
import Modal, { IModalRef } from 'components/helpers/modals/Modal';
import { GetIcon } from 'components/helpers/other/GetIcon';
import UserForm from './UserForm';
import { patchUserFx } from '../../../models/users';

interface IEditUserInfoModalProps {
	row: ILongUser;
	fetchUsersList: () => void;
}

const EditUserInfoModal = ({
	row,
	fetchUsersList,
}: IEditUserInfoModalProps): React.ReactElement => {
	const patchUserPending = useStore(patchUserFx.pending);

	const modalRef = useRef<IModalRef>(null);
	const formikRef = useRef<IFormikRef>(null);

	const handleModalOpen = () => {
		if (modalRef.current) modalRef.current.handleModalOpen();
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const submitFunc = async (data: IRegisterBasicUserParams) => {
		await patchUserFx({ userId: row.id, data: _.omit(data, ['password', 'type']) });
		if (formikRef.current) formikRef.current.resetForm();
		handleModalClose();
		await fetchUsersList();
	};

	const handleSubmit = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
	};

	const modalContent = {
		toggleChildren: (
			<Tooltip title='Изменить данные пользователя' placement='top' arrow>
				<IconButton color='inherit' onClick={handleModalOpen}>
					{GetIcon(editOutlineIcon)}
				</IconButton>
			</Tooltip>
		),
		contentChildren: (
			<Stack spacing={2}>
				<Stack>
					<Typography align='center' variant='h4'>
						Изменить данные пользователя
					</Typography>
				</Stack>
				<UserForm ref={formikRef} submitFunc={submitFunc} user={row} />
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' spacing={1} sx={{ mt: 1, width: '100%' }}>
				<Button
					fullWidth
					size='large'
					variant='contained'
					color='secondary'
					onClick={handleModalClose}
				>
					Отменить
				</Button>
				<LoadingButton
					loading={patchUserPending}
					fullWidth
					size='large'
					variant='contained'
					onClick={handleSubmit}
				>
					Изменить
				</LoadingButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
		/>
	);
};

export default EditUserInfoModal;
