/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {useField, useFormikContext} from 'formik';
import {useState} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterLuxon';
import {TextField} from "@mui/material";
import {DateTime} from "luxon";

export default function LocalizedDatePicker({
	                                            minDate,
	                                            name,
	                                            label,
	                                            fullWidth = false
                                            }: { minDate?: DateTime, name: string, label: string, fullWidth?: boolean }) {
	const {isSubmitting} = useFormikContext();
	const fieldProps = useField(name);
	const [field, meta, helpers] = fieldProps;

	const [error, setError] = useState(false);

	return (
		<LocalizationProvider dateAdapter={DateAdapter} locale='ru'>
			<DatePicker
				minDate={minDate}
				disabled={isSubmitting}
				mask='__.__.____'
				label={label}
				{...field}
				onChange={(newValue) => {
					setError(!!newValue && !!newValue.invalid);
					helpers.setValue(newValue);
				}}
				renderInput={(params) => (
					<TextField
						fullWidth={fullWidth}
						{...params}
						error={!!meta.error || error}
					/>
				)}/>
		</LocalizationProvider>
	);
}