/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {
	fetchUserCompanyCard,
	fetchUsersList,
	patchUser,
	registerUser,
	patchUserCompanyCard,
	addSlotToCompanyCard,
	deleteSlotFromCompanyCard,
	patchSlotTitleCompanyCard,
} from 'api';
import {
	addSlotToCompanyCardFx,
	deleteSlotFromCompanyCardFx,
	fetchUserCompanyCardFx,
	fetchUsersListFx,
	patchSlotTitleCompanyCardFx,
	patchUserCompanyCardFx,
	patchUserFx,
	registerUserFx,
} from './index';

fetchUsersListFx.use(fetchUsersList);
registerUserFx.use(registerUser);
patchUserFx.use(patchUser);
fetchUserCompanyCardFx.use(fetchUserCompanyCard);
patchUserCompanyCardFx.use(patchUserCompanyCard);
addSlotToCompanyCardFx.use(addSlotToCompanyCard);
patchSlotTitleCompanyCardFx.use(patchSlotTitleCompanyCard);
deleteSlotFromCompanyCardFx.use(deleteSlotFromCompanyCard);
