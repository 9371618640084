/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { List, Stack, ListItem, IconButton, ListItemText, Tooltip, Card } from '@mui/material';
import trashIcon from '@iconify-icons/gg/trash';
import fileDocumentIcon from '@iconify-icons/gg/file-document';
import {
	ICompanyCard,
	ILongUser,
	IResponsesListItem,
	IIssueWithExpandUsers,
} from '../../../../types';
import { GetIcon } from '../../../helpers/other/GetIcon';
import SlotFormModal from './SlotFormModal';
import { deleteSlotFromCompanyCardFx } from '../../../../models/users';
import NotFoundCard from '../../../helpers/other/NotFoundCard';
import { downloadFile } from '../../../../shared/functions';
import { downloadCompanyCardSlotFileFx } from '../../../../models/issues';

interface ISlotsTabProps {
	user?: ILongUser;
	companyCard: ICompanyCard;
	fetchUserCompanyCard: () => void;
	chatInfo?: IIssueWithExpandUsers;
	responseItem?: IResponsesListItem;
}

const SlotsTab = ({
	user,
	companyCard,
	fetchUserCompanyCard,
	chatInfo,
	responseItem,
}: ISlotsTabProps): React.ReactElement => (
	<Stack>
		{!companyCard.slots.length ? (
			<NotFoundCard name='Документы карточки' variant='h5' />
		) : (
			<List sx={{ p: 0 }}>
				<Stack spacing={1}>
					{companyCard.slots.map(slot => (
						<Card sx={{ p: 0.5, m: 0 }} key={slot._id}>
							<ListItem
								secondaryAction={
									<Stack direction='row' spacing={1}>
										{user ? (
											<>
												<SlotFormModal
													user={user}
													slot={slot}
													fetchUserCompanyCard={fetchUserCompanyCard}
												/>
												<Tooltip title='Удалить документ' placement='top' arrow>
													<IconButton
														edge='end'
														onClick={async () => {
															await deleteSlotFromCompanyCardFx({
																userId: user.id,
																slotId: slot._id,
															});
															await fetchUserCompanyCard();
														}}
													>
														{GetIcon(trashIcon)}
													</IconButton>
												</Tooltip>
											</>
										) : (
											<Tooltip title='Скачать документ' placement='top' arrow>
												<IconButton
													edge='end'
													onClick={async () => {
														const filename =
															slot.file && slot.file.filename
																? slot.file.filename
																: 'Без названия';
														const blob = await downloadCompanyCardSlotFileFx({
															issueId: chatInfo!.id,
															responseId: responseItem!.id,
															slotId: slot._id,
															fileId: slot.file!._id,
														});
														downloadFile(blob, filename);
													}}
												>
													{GetIcon(fileDocumentIcon)}
												</IconButton>
											</Tooltip>
										)}
									</Stack>
								}
							>
								<ListItemText primary={slot.title} />
							</ListItem>
						</Card>
					))}
				</Stack>
			</List>
		)}
	</Stack>
);

export default SlotsTab;
