/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {apiRequest, IData} from './index';
import {
	ICompanyCard,
	IFetchUsersListResponse,
	ILongUser,
	IPatchUserCompanyCardParams,
	IPatchUserParams,
	IRegisterUserParams,
} from '../types';
import {
	FETCH_USER_COMPANY_CARD_ERROR,
	FETCH_USERS_LIST_ERROR,
	PATCH_USER_COMPANY_CARD_ERROR,
	PATCH_USER_ERROR,
	REGISTER_USER_ERROR,
	ADD_SLOT_TO_COMPANY_CARD_ERROR,
	PATCH_SLOT_TITLE_COMPANY_CARD_ERROR,
	DELETE_SLOT_TITLE_COMPANY_CARD_ERROR,
} from '../shared/errors';

export const fetchUsersList = async () => {
	const response = await apiRequest<IData<IFetchUsersListResponse>>({
		url: '/api/admin/user/list',
		method: 'GET',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_USERS_LIST_ERROR);
	}
	return response.data.data;
};

export const registerUser = async (data: IRegisterUserParams) => {
	const response = await apiRequest<IData<ILongUser>>({
		url: '/api/admin/user/register',
		method: 'POST',
		data,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || REGISTER_USER_ERROR);
	}
	return response.data.data;
};

export const patchUser = async ({ userId, data }: { userId: string; data: IPatchUserParams }) => {
	const response = await apiRequest<IData<ILongUser>>({
		url: `/api/admin/user/${userId}`,
		method: 'PATCH',
		data,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || PATCH_USER_ERROR);
	}
	return response.data.data;
};

export const fetchUserCompanyCard = async ({ userId }: { userId: string }) => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: `/api/admin/user/${userId}/companyCard`,
		method: 'GET',
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || FETCH_USER_COMPANY_CARD_ERROR);
	}
	return response.data.data;
};

export const patchUserCompanyCard = async ({
	userId,
	data,
}: {
	userId: string;
	data: IPatchUserCompanyCardParams;
}) => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: `/api/admin/user/${userId}/companyCard`,
		method: 'PATCH',
		data,
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || PATCH_USER_COMPANY_CARD_ERROR);
	}
	return response.data.data;
};

export const addSlotToCompanyCard = async ({
	userId,
	title,
}: {
	userId: string;
	title: string;
}) => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: `/api/admin/user/${userId}/companyCard/addSlot`,
		method: 'POST',
		data: { title },
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || ADD_SLOT_TO_COMPANY_CARD_ERROR);
	}
	return response.data.data;
};

export const patchSlotTitleCompanyCard = async ({
	userId,
	slotId,
	title,
}: {
	userId: string;
	slotId: string;
	title: string;
}) => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: `/api/admin/user/${userId}/companyCard/slot/${slotId}`,
		method: 'POST',
		data: { title },
	});
	if ('error' in response.data) {
		throw new Error(response.data.error || PATCH_SLOT_TITLE_COMPANY_CARD_ERROR);
	}
	return response.data.data;
};

export const deleteSlotFromCompanyCard = async ({
	userId,
	slotId,
}: {
	userId: string;
	slotId: string;
}) => {
	const response = await apiRequest<IData<''>>({
		url: `/api/admin/user/${userId}/companyCard/slot/${slotId}`,
		method: 'DELETE',
	});
	if (response.data) {
		throw new Error(DELETE_SLOT_TITLE_COMPANY_CARD_ERROR);
	}
	return response.status;
};
