/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useStore } from 'effector-react';
import browseIcon from '@iconify-icons/gg/browse';
import { fetchIssuesListFx } from 'models/issues';
import { IIssueWithExpandUsers } from 'types';
import Spinner from 'components/helpers/other/Spinner';
import NotFoundCard from 'components/helpers/other/NotFoundCard';
import PaginationTable from 'components/helpers/table/PaginationTable';
import { openChat } from 'components/features/chat/Chat';

interface IConversationsTabProps {
	issueTypes?: Array<string>;
}

const ConversationsTab = ({ issueTypes }: IConversationsTabProps): React.ReactElement => {
	const fetchIssuesListPending = useStore(fetchIssuesListFx.pending);

	const [issuesList, setIssuesList] = useState<null | IIssueWithExpandUsers[]>(null);

	const fetchIssuesList = async () => {
		const response = await fetchIssuesListFx({});
		const filteredIssues = issueTypes
			? response.docs.filter(item => issueTypes.includes(item.type))
			: response.docs.filter(item => item.type !== 'refuseNDA');
		setIssuesList(filteredIssues);
	};

	useEffect(() => {
		fetchIssuesList().then();
	}, []);

	return (
		<Stack>
			{(!issuesList || fetchIssuesListPending) && <Spinner size='3rem' />}
			{
				// prettier-ignore
				(issuesList && !issuesList.length) && <NotFoundCard name='Переписки' />
			}
			{
				// prettier-ignore
				(issuesList && !!issuesList.length) && (
					<PaginationTable
						options={[
							{ key: 'title', title: 'Название' },
							{
								key: 'description',
								title: 'Описание',
								isPopover: true,
							},
							{ key: 'authorName', title: 'Автор' },
							...!issueTypes ? [{ key: 'participantName', title: 'Собеседник' }] : [],
							{ key: 'actions', title: '' },
						].map(item => ({ ...item, sx: { textAlign: 'center' } }))}
						rows={issuesList.map(item => ({
							...item,
							authorName: item.author.name,
							...!issueTypes ? {
								// @ts-ignore
								participantName: item.participant.name
							} : {},
						}))}
						rowsActions={[
							{
								id: 'open-chat-button',
								tooltip: 'Открыть чат',
								icon: browseIcon,
								onClick: item => openChat(item as IIssueWithExpandUsers, !issueTypes),
							},
						]}
					/>
				)
			}
		</Stack>
	);
};

ConversationsTab.defaultProps = {
	issueTypes: undefined,
};

export default ConversationsTab;
