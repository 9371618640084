/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useImperativeHandle, useRef, useState } from 'react';
import {
	Button,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useStore } from 'effector-react';
import passwordIcon from '@iconify-icons/gg/password';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { IFormikRef, ILongUser } from 'types';
import Modal, { IModalRef } from 'components/helpers/modals/Modal';
import { GetIcon } from 'components/helpers/other/GetIcon';
import { patchUserFx } from 'models/users';

interface IChangeUserPasswordModalProps {
	row: ILongUser;
	fetchUsersList: () => void;
}

const ChangeUserPasswordModal = ({
	row,
	fetchUsersList,
}: IChangeUserPasswordModalProps): React.ReactElement => {
	const patchUserPending = useStore(patchUserFx.pending);

	const modalRef = useRef<IModalRef>(null);
	const formikRef = useRef<IFormikRef>(null);

	const [showPassword, setShowPassword] = useState(false);

	const ChangePasswordFormSchema = Yup.object().shape({
		password: Yup.string()
			.required('Поле является обязательным')
			.min(8, 'Длина пароля должна быть не менее 8 символов'),
	});

	const formik = useFormik({
		initialValues: {
			password: '',
		},
		validationSchema: ChangePasswordFormSchema,
		onSubmit: async data => {
			await patchUserFx({ userId: row.id, data });
			if (formikRef.current) formikRef.current.resetForm();
			handleModalClose();
			await fetchUsersList();
		},
	});

	const { errors, touched, getFieldProps } = formik;

	useImperativeHandle(formikRef, () => ({ ...formik }));

	const handleModalOpen = () => {
		if (modalRef.current) modalRef.current.handleModalOpen();
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const handleSubmit = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
	};

	const modalContent = {
		toggleChildren: (
			<Tooltip title='Изменить пароль' placement='top' arrow>
				<IconButton color='inherit' onClick={handleModalOpen}>
					{GetIcon(passwordIcon)}
				</IconButton>
			</Tooltip>
		),
		contentChildren: (
			<Stack spacing={2}>
				<Stack>
					<Typography align='center' variant='h4'>
						Изменить пароль пользователя
					</Typography>
				</Stack>
				<FormikProvider value={formik}>
					<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
						<TextField
							fullWidth
							type={showPassword ? 'text' : 'password'}
							label='Новый пароль'
							{...getFieldProps('password')}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
											<Icon icon={showPassword ? eyeFill : eyeOffFill} />
										</IconButton>
									</InputAdornment>
								),
							}}
							error={Boolean(touched.password && errors.password)}
							helperText={touched.password && errors.password}
						/>
					</Form>
				</FormikProvider>
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' spacing={1} sx={{ mt: 1, width: '100%' }}>
				<Button
					fullWidth
					size='large'
					variant='contained'
					color='secondary'
					onClick={handleModalClose}
				>
					Отменить
				</Button>
				<LoadingButton
					loading={patchUserPending}
					fullWidth
					size='large'
					variant='contained'
					onClick={handleSubmit}
				>
					Изменить
				</LoadingButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
		/>
	);
};

export default ChangeUserPasswordModal;
