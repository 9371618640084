/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { GetIcon } from 'components/helpers/other/GetIcon';
import FormikAutocomplete from 'components/helpers/forms/FormikAutocomplete';
import {ILongUser, IRegisterBasicUserParams, IRegisterUserParams} from 'types';
import { userTypes } from 'shared/variables';
import { isInn } from 'shared/validators/inn';

interface IUserFormProps {
	submitFunc: (values: IRegisterBasicUserParams) => void;
	user?: ILongUser;
}

interface IFormValues extends Omit<IRegisterBasicUserParams, 'type'> {
	type: { name: string } | '';
}

export const helperTexts = {
	name: 'Полное наименование, например ООО Рога и Копыта',
}

const UserForm = forwardRef(({ submitFunc, user }: IUserFormProps, ref) => {
	const [showPassword, setShowPassword] = useState(false);

	const UserFormSchema = Yup.object().shape({
		name: Yup.string().required('Поле является обязательным'),
		email: Yup.string().email('Почта введена некорректно').required('Поле является обязательным'),
		inn: Yup.string().required('Поле является обязательным').test('isInn', '', isInn),
		type: !user ? Yup.object().required('Поле является обязательным') : Yup.object(),
		password: !user
			? Yup.string()
					.required('Поле является обязательным')
					.min(8, 'Длина пароля должна быть не менее 8 символов')
			: Yup.string(),
	});

	const formik = useFormik<IFormValues>({
		initialValues: {
			name: user ? user.name : '',
			email: user ? user.email : '',
			inn: user ? user.inn : '',
			type: '',
			password: '',
		},
		validationSchema: UserFormSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: values => submitFunc({ ...values, type: values.type ? values.type.name : '' }),
	});

	const { errors, touched, handleSubmit, getFieldProps } = formik;

	useImperativeHandle(ref, () => ({ ...formik }));

	return (
		<Stack>
			<FormikProvider value={formik}>
				<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<TextField
							fullWidth
							type='name'
							label='Наименование компании'
							{...getFieldProps('name')}
							error={Boolean(touched.name && errors.name)}
							helperText={(touched.name && errors.name) || helperTexts.name}
						/>
						<TextField
							fullWidth
							type='email'
							label='Почта'
							{...getFieldProps('email')}
							error={Boolean(touched.email && errors.email)}
							helperText={touched.email && errors.email}
						/>
						<TextField
							fullWidth
							label='ИНН'
							{...getFieldProps('inn')}
							error={Boolean(touched.inn && errors.inn)}
							helperText={touched.inn && errors.inn}
						/>
						{!user && (
							<>
								<FormikAutocomplete
									name='type'
									options={userTypes}
									textFieldProps={{ label: 'Тип пользователя' }}
								/>
								<TextField
									fullWidth
									type={showPassword ? 'text' : 'password'}
									label='Пароль'
									{...getFieldProps('password')}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													edge='end'
												>
													{GetIcon(showPassword ? eyeFill : eyeOffFill)}
												</IconButton>
											</InputAdornment>
										),
									}}
									error={Boolean(touched.password && errors.password)}
									helperText={touched.password && errors.password}
								/>
							</>
						)}
					</Stack>
				</Form>
			</FormikProvider>
		</Stack>
	);
});

UserForm.defaultProps = {
	user: undefined,
};

export default UserForm;
