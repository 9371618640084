/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

// theme
export const SidebarWidth = 275;
export const TopbarHeight = 70;
export const USER_THEME_MODE = 'USER_THEME_MODE';

// auth
export const USER_TOKEN = 'USER_TOKEN';
export const USER_DATA = 'USER_DATA';

// users
export const userTypes = [
	{ name: 'admin' },
	{ name: 'factor' },
	{ name: 'supplier' },
	{ name: 'debtor' },
];
