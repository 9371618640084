/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { Snackbar } from '@mui/material';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

export const setOpenErrorSnackbar = createEvent<boolean>();
export const setMessageErrorSnackbar = createEvent<string>();
export const $errorSnackbar = createStore({
	open: false,
	message: '',
})
	.on(setOpenErrorSnackbar, (state, open) => ({ ...state, open }))
	.on(setMessageErrorSnackbar, (state, message) => ({ ...state, message }));

export const openErrorSnackbar = (message: string) => {
	setOpenErrorSnackbar(true);
	setMessageErrorSnackbar(message);
};

const ErrorSnackbar = (): React.ReactElement => {
	const { open, message } = useStore($errorSnackbar);

	const handleClose = () => {
		setOpenErrorSnackbar(false);
		setMessageErrorSnackbar('');
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={open}
			message={message}
			autoHideDuration={3000}
			onClose={handleClose}
			sx={{
				'.MuiPaper-root': {
					color: 'grey.A100',
					backgroundColor: 'error.dark',
				},
				'.MuiSnackbarContent-message': {
					textAlign: 'center',
				},
			}}
		/>
	);
};

export default ErrorSnackbar;
