/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Autocomplete, Chip, createFilterOptions, TextField, Typography } from '@mui/material';

const defaultOptionLabel = (option: { name: string }) => (option ? option.name : '');

const FormikAutocomplete = (props: {
	name: string;
	options: any;
	getOptionLabel?: (option: any) => string;
	disabled?: boolean;
	multiple?: boolean;
	limit?: number;
	textFieldProps?: { label: string };
	defaultValue?: object;
	fullWidth?: boolean;
}): React.ReactElement => {
	const {
		name,
		disabled,
		options,
		getOptionLabel,
		textFieldProps,
		multiple = false,
		limit = 300,
		defaultValue = '',
		fullWidth = true
	} = props;

	const { isSubmitting } = useFormikContext();
	const fieldProps = useField(name);
	const [field, meta, helpers] = fieldProps;

	const showError = meta.touched && !!meta.error;

	const filterOptions = createFilterOptions<{ name: string }>({ limit });

	return (
		<Autocomplete
			filterOptions={filterOptions}
			multiple={multiple}
			fullWidth={fullWidth}
			clearText='Очистить'
			noOptionsText='Нет совпадений'
			openText='Открыть'
			disabled={disabled || isSubmitting}
			options={options}
			getOptionLabel={getOptionLabel || defaultOptionLabel}
			{...field}
			onChange={(_, value) => {
				helpers.setValue(value || (multiple ? [] : defaultValue));
			}}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip label={<Typography>{option.name}</Typography>} {...getTagProps({ index })} />
				))
			}
			onBlur={() => helpers.setTouched(true)}
			renderInput={params => (
				<TextField
					{...params}
					error={showError}
					helperText={showError && meta.error}
					inputProps={{ ...params.inputProps }}
					{...textFieldProps}
				/>
			)}
		/>
	);
};

export default FormikAutocomplete;
