/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useState } from 'react';
import { set } from 'local-storage';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Menu,
	Typography,
	IconButton,
	Stack,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { USER_THEME_MODE } from 'shared/variables';
import { $customizer, setThemeMode } from 'models/customizer';
import { $user, fetchCurrentUserFx, logout } from 'models/auth';
import Spinner from 'components/helpers/other/Spinner';
// @ts-ignore
import UserAvatar from '../../../../assets/images/userLogo.svg';

const ProfileDropdown = (): React.ReactElement => {
	const navigate = useNavigate();

	const { user } = useStore($user);
	const customizer = useStore($customizer);
	const fetchCurrentUserPending = useStore(fetchCurrentUserFx.pending);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpen = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		if (!user || !('type' in user)) await fetchCurrentUserFx();
	};
	const handleClose = () => setAnchorEl(null);

	const handleToggleTheme = (event: any) => {
		const mode = event.target.checked ? 'dark' : 'light';
		setThemeMode(mode);
		set(USER_THEME_MODE, mode);
	};

	const handleLogout = async () => {
		await logout();
		navigate('/login', { replace: true });
	};

	return (
		<>
			<IconButton
				aria-label='menu'
				color='inherit'
				aria-controls='profile-menu'
				aria-haspopup='true'
				onClick={handleOpen}
			>
				<Stack alignItems='center'>
					<Box component='img' src={UserAvatar} sx={{ width: 30, height: 30 }} />
				</Stack>
			</IconButton>
			<Menu
				id='profile-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						width: '385px',
						right: 0,
						top: '70px !important',
					},
					'& .MuiList-padding': { p: 4 },
				}}
			>
				{fetchCurrentUserPending ? (
					<Spinner />
				) : (
					<Stack direction='row' alignItems='center' sx={{ mb: 3 }}>
						<Box component='img' src={UserAvatar} sx={{ width: 90, height: 90 }} />
						<Box sx={{ ml: 2 }}>
							<Typography variant='h4' sx={{ lineHeight: '1.235' }}>
								{
									// @ts-ignore
									user?.name || 'Имя не указано'
								}
							</Typography>
							<Typography variant='h4' sx={{ lineHeight: '1.235' }}>
								{user?.email || 'Почта не указана'}
							</Typography>
						</Box>
					</Stack>
				)}
				<Stack spacing={1}>
					<Stack sx={{ alignItems: 'center' }}>
						<FormControlLabel
							checked={customizer.activeMode === 'dark'}
							control={<Switch color='primary' />}
							label='Темный режим'
							onChange={handleToggleTheme}
						/>
					</Stack>
					<Stack>
						<Button variant='contained' color='primary' onClick={handleLogout}>
							Выйти
						</Button>
					</Stack>
				</Stack>
			</Menu>
		</>
	);
};

export default ProfileDropdown;
