/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { SxProps } from '@mui/system';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import { GetIcon } from '../../helpers/other/GetIcon';
import { addResponseToIssueFx } from '../../../models/issues';
import AddAttachmentModal from './AddAttachmentModal';
import { IIssueWithExpandUsers } from '../../../types';

interface IMessageFormProps {
	sx?: SxProps;
	chatInfo: IIssueWithExpandUsers;
	fetchResponsesList: () => void;
}

const MessageForm = ({
	sx,
	chatInfo,
	fetchResponsesList,
}: IMessageFormProps): React.ReactElement => {
	const formik = useFormik({
		initialValues: {
			text: '',
		},
		onSubmit: async ({ text }) => {
			if (chatInfo) {
				await addResponseToIssueFx({ issueId: chatInfo.id, text });
				fetchResponsesList();
			}
		},
	});

	const { handleSubmit, values, handleChange } = formik;

	return (
		<Stack sx={{ py: 2, px: 1, ...sx }}>
			<FormikProvider value={formik}>
				<Form autoComplete='off' noValidate onSubmit={handleSubmit} style={{ height: '100%' }}>
					<Stack direction='row' sx={{ height: '100%', alignItems: 'center' }}>
						<Stack sx={{ mr: 0.5 }}>
							<AddAttachmentModal
								chatInfo={chatInfo}
								fetchResponsesList={fetchResponsesList}
							/>
						</Stack>
						<input
							placeholder='Сообщение...'
							name='text'
							value={values.text}
							onChange={handleChange}
							style={{
								width: '100%',
								height: '100%',
								marginRight: '5px',
								backgroundColor: 'transparent',
								outline: 'none',
								border: 'none',
								color: 'inherit',
								fontSize: '16px',
							}}
						/>
						<Stack direction='row'>
							<Tooltip title='Отправить' placement='top' arrow>
								<IconButton color='inherit' type='submit'>
									{GetIcon(sendIcon)}
								</IconButton>
							</Tooltip>
						</Stack>
					</Stack>
				</Form>
			</FormikProvider>
		</Stack>
	);
};

export default MessageForm;
