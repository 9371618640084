/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import LogoIcon from './LogoIcon';

const Logo = () => (
	<Box sx={{ mb: 2, p: 1 }}>
		<Stack direction='row' sx={{ alignItems: 'center' }}>
			<LogoIcon sx={{ mr: 1 }} />
			<Typography variant='h3'>Факторинг</Typography>
		</Stack>
	</Box>
);

export default Logo;
