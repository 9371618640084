/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {
	downloadCompanyCardSlotFileFx,
	downloadResponseFileFx,
	fetchIssuesListFx,
	fetchResponsesListFx,
	addResponseToIssueFx,
	addResponseFileToIssueFx, completeIssueFx, fetchFinancingIssuesListFx, fetchShipmentsListFx,
} from './index';
import {
	downloadResponseFile,
	fetchIssuesList,
	fetchResponsesList,
	downloadCompanyCardSlotFile,
	addResponseToIssue,
	addResponseFileToIssue, completeIssue, fetchFinancingIssuesList, fetchShipmentsList,
} from '../../api';

fetchIssuesListFx.use(fetchIssuesList);
fetchFinancingIssuesListFx.use(fetchFinancingIssuesList);
fetchShipmentsListFx.use(fetchShipmentsList);
fetchResponsesListFx.use(fetchResponsesList);
downloadResponseFileFx.use(downloadResponseFile);
downloadCompanyCardSlotFileFx.use(downloadCompanyCardSlotFile);
addResponseToIssueFx.use(addResponseToIssue);
addResponseFileToIssueFx.use(addResponseFileToIssue);
completeIssueFx.use(completeIssue);
