/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { createEffect, Effect, merge } from 'effector';
import {
	ICompanyCard,
	IFetchUsersListResponse,
	ILongUser,
	IPatchUserCompanyCardParams,
	IPatchUserParams,
	IRegisterUserParams,
} from 'types';

// user
export const fetchUsersListFx = createEffect<void, IFetchUsersListResponse>();
export const registerUserFx = createEffect<IRegisterUserParams, ILongUser>();
export const patchUserFx = createEffect<{ userId: string; data: IPatchUserParams }, ILongUser>();

// companyCard
export const fetchUserCompanyCardFx = createEffect<{ userId: string }, any>();
export const patchUserCompanyCardFx = createEffect<
	{ userId: string; data: IPatchUserCompanyCardParams },
	any
>();
export const addSlotToCompanyCardFx = createEffect<
	{ userId: string; title: string },
	ICompanyCard
>();
export const patchSlotTitleCompanyCardFx = createEffect<
	{ userId: string; slotId: string; title: string },
	ICompanyCard
>();
export const deleteSlotFromCompanyCardFx = createEffect<{ userId: string; slotId: string }, any>();

export const $usersEffectsFailData = merge(
	[
		fetchUsersListFx,
		registerUserFx,
		patchUserFx,
		fetchUserCompanyCardFx,
		patchUserCompanyCardFx,
		addSlotToCompanyCardFx,
		deleteSlotFromCompanyCardFx,
	].map((item: Effect<any, any>) => item.failData)
);
