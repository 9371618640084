/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, Effect, merge} from 'effector';
import {
	IAddResponseFileToIssueParams,
	IDownloadCompanyCardSlotFileParams,
	IDownloadResponseFileParams,
	IFetchIssuesListResponse,
	IFetchResponsesListResponse, IIssueBaseResponseFullStringParams,
	IIssueFileResponseFullStringParams,
	IIssueResponseFullStringParams, IIssueWithExpandUsers,
} from 'types';
import {IShipment} from "../../pages/dashboard/Shipments";

export const fetchIssuesListFx = createEffect<{ participantId?: string },
	IFetchIssuesListResponse>();
export const fetchFinancingIssuesListFx = createEffect<void, IIssueWithExpandUsers[]>();
export const fetchShipmentsListFx = createEffect<void, IShipment[]>();
export const fetchResponsesListFx = createEffect<{ issueId: string },
	IFetchResponsesListResponse>();
export const downloadResponseFileFx = createEffect<IDownloadResponseFileParams, Blob>();
export const downloadCompanyCardSlotFileFx = createEffect<IDownloadCompanyCardSlotFileParams,
	Blob>();
export const addResponseToIssueFx = createEffect<{ issueId: string; text: string },
	IIssueResponseFullStringParams>();
export const addResponseFileToIssueFx = createEffect<IAddResponseFileToIssueParams,
	IIssueFileResponseFullStringParams>();

export const completeIssueFx = createEffect<IAddResponseFileToIssueParams,
	IIssueBaseResponseFullStringParams>();

export const $issuesEffectsFailData = merge(
	[
		fetchIssuesListFx,
		fetchResponsesListFx,
		downloadResponseFileFx,
		downloadCompanyCardSlotFileFx,
		addResponseToIssueFx,
		addResponseFileToIssueFx,
	].map((item: Effect<any, any>) => item.failData)
);
