/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useEffect, useState } from 'react';
import { Card, Divider, Drawer, Stack, Typography } from '@mui/material';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { IIssueWithExpandUsers, IResponsesListItem } from 'types';
import MessageList from './MessageList';
import { fetchResponsesListFx } from '../../../models/issues';
import Spinner from '../../helpers/other/Spinner';
import MessageForm from './MessageForm';

interface IChatProps {
	open: boolean;
	chatInfo: null | IIssueWithExpandUsers;
	readOnly: boolean;
}

export const setOpenChat = createEvent<boolean>();
export const setChatInfo = createEvent<null | IIssueWithExpandUsers>();
export const setReadOnlyChat = createEvent<boolean>();
export const $chat = createStore<IChatProps>({
	open: false,
	chatInfo: null,
	readOnly: false,
})
	.on(setOpenChat, (state, open) => ({ ...state, open }))
	.on(setChatInfo, (state, chatInfo) => ({ ...state, chatInfo }))
	.on(setReadOnlyChat, (state, readOnly) => ({ ...state, readOnly }));

export const openChat = (chatInfo: null | IIssueWithExpandUsers, readOnly: boolean) => {
	setOpenChat(true);
	setChatInfo(chatInfo);
	setReadOnlyChat(readOnly);
};

const Chat = (): React.ReactElement => {
	const { open, chatInfo, readOnly } = useStore($chat);
	const fetchResponsesListPending = useStore(fetchResponsesListFx.pending);
	const [responsesList, setResponsesList] = useState<null | IResponsesListItem[]>(null);

	const fetchResponsesList = () => {
		if (open && chatInfo) {
			setTimeout(async () => {
				const response = await fetchResponsesListFx({ issueId: chatInfo.id });
				setResponsesList(response.docs);
			}, 300);
		}
	};

	useEffect(() => {
		fetchResponsesList();
	}, [open, chatInfo]);

	return (
		<Drawer
			open={open}
			onClose={() => {
				setOpenChat(false);
				setReadOnlyChat(false);
				setTimeout(() => {
					setChatInfo(null);
					setResponsesList(null);
				}, 300);
			}}
			transitionDuration={300}
		>
			<Stack
				sx={{
					p: 2.5,
					width: '520px',
					height: '100%',
					alignItems: 'center',
				}}
			>
				<Card
					sx={{
						width: '100%',
						height: '100%',
						m: 0,
						p: 0,
					}}
				>
					{!responsesList || !chatInfo || fetchResponsesListPending ? (
						<Stack sx={{ height: '100%', display: 'grid', placeItems: 'center' }}>
							<Spinner size='3rem' />
						</Stack>
					) : (
						<Stack sx={{ height: '100%' }}>
							{responsesList.length ? (
								<MessageList
									sx={{ height: !readOnly ? '93%' : '100%' }}
									chatInfo={chatInfo}
									responsesList={responsesList}
									fetchResponsesList={fetchResponsesList}
								/>
							) : (
								<Stack sx={{ height: '100%', display: 'grid', placeItems: 'center' }}>
									<Typography variant='body2'>
										<strong>Сообщения отсутствуют</strong>
									</Typography>
								</Stack>
							)}
							{!readOnly && (
								<>
									<Divider />
									<MessageForm
										sx={{ height: '7%' }}
										chatInfo={chatInfo}
										fetchResponsesList={fetchResponsesList}
									/>
								</>
							)}
						</Stack>
					)}
				</Card>
			</Stack>
		</Drawer>
	);
};

export default Chat;
