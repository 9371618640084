/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useState } from 'react';
import { Box, Container, experimentalStyled, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useStore } from 'effector-react';
import { TopbarHeight } from 'shared/variables';
import { $customizer } from 'models/customizer';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const MainWrapper = experimentalStyled('div')(() => ({
	display: 'flex',
	minHeight: '100vh',
	overflow: 'hidden',
	width: '100%',
}));

const PageWrapper = experimentalStyled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',

	backgroundColor: theme.palette.background.default,
	[theme.breakpoints.up('lg')]: {
		paddingTop: TopbarHeight,
	},
	[theme.breakpoints.down('lg')]: {
		paddingTop: '64px',
	},
}));

const DashboardLayout = (): React.ReactElement => {
	const theme = useTheme();
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

	const customizer = useStore($customizer);

	const [isSidebarOpen, setSidebarOpen] = useState(true);
	const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);

	return (
		<MainWrapper>
			<Header
				sx={{
					paddingLeft: isSidebarOpen && lgUp ? '265px' : '',
					backgroundColor: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
				}}
				toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
				toggleMobileSidebar={() => setMobileSidebarOpen(true)}
			/>

			<Sidebar
				isSidebarOpen={isSidebarOpen}
				isMobileSidebarOpen={isMobileSidebarOpen}
				onSidebarClose={() => setMobileSidebarOpen(false)}
			/>

			<PageWrapper>
				<Container
					maxWidth={false}
					sx={{
						paddingTop: '20px',
						paddingLeft: isSidebarOpen && lgUp ? '280px!important' : '',
					}}
				>
					<Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
						<Outlet />
					</Box>
				</Container>
			</PageWrapper>
		</MainWrapper>
	);
};

export default DashboardLayout;
