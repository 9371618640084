/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useStore } from 'effector-react';
import { fetchUsersListFx } from 'models/users';
import Spinner from 'components/helpers/other/Spinner';
import PaginationTable from 'components/helpers/table/PaginationTable';
import NotFoundCard from 'components/helpers/other/NotFoundCard';
import CreateUserModal from 'components/pages/users/CreateUserModal';
import EditUserInfoModal from 'components/pages/users/EditUserInfoModal';
import ChangeUserPasswordModal from 'components/pages/users/ChangeUserPasswordModal';
import { ILongUser } from 'types';
import CompanyCardModal from '../../components/features/company-card/CompanyCardModal';

const UsersPage = (): React.ReactElement => {
	const fetchUsersListPending = useStore(fetchUsersListFx.pending);

	const [users, setUsers] = useState<null | ILongUser[]>(null);

	const fetchUsersList = async () => {
		const response = await fetchUsersListFx();
		setUsers(response.docs);
	};

	useEffect(() => {
		fetchUsersList().then();
	}, []);

	return (
		<Box>
			{!users || fetchUsersListPending ? (
				<Spinner size='5rem' />
			) : (
				<>
					<Stack
						sx={{
							mb: 3,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant='h2'>Пользователи</Typography>
						<CreateUserModal fetchUsersList={fetchUsersList} />
					</Stack>
					{!users.length ? (
						<NotFoundCard name='Пользователи' />
					) : (
						<PaginationTable
							options={[
								{ key: 'name', title: 'Наименование компании', sx: { textAlign: 'center' } },
								{ key: 'email', title: 'Почта', sx: { textAlign: 'center' } },
								{ key: 'inn', title: 'ИНН', sx: { textAlign: 'center' } },
								{ key: 'type', title: 'Тип пользователя', sx: { textAlign: 'center' } },
								{ key: 'actions', title: '', sx: { textAlign: 'center' } },
							]}
							rows={users.map(user => ({
								...user,
								isAdmin: user.isAdmin ? '+' : '-',
							}))}
							rowsActions={[
								{
									id: 'user-company-card-modal',
									component: CompanyCardModal,
								},
								{
									id: 'edit-user-info-component',
									component: EditUserInfoModal,
									props: { fetchUsersList },
								},
								{
									id: 'edit-password-user-component',
									component: ChangeUserPasswordModal,
								},
							]}
						/>
					)}
				</>
			)}
		</Box>
	);
};

export default UsersPage;
