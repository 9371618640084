/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import axiosOrig from 'axios';

const axios = axiosOrig.create({
	baseURL: `${process.env.REACT_APP_KND_BACKEND_URL}${process.env.REACT_APP_KND_BACKEND_PORT ? (`:${process.env.REACT_APP_KND_BACKEND_PORT}`) : ''}`,
});

export { axios };
