/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import PaginationTable from 'components/helpers/table/PaginationTable';
import {Box} from "@mui/system";
import {DateTime} from "luxon";
import {Card, CardContent, Grid, Stack, TextField, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import FormikAutocomplete from 'components/helpers/forms/FormikAutocomplete';
import {IUser} from "../../types";
import NotFoundCard from "../../components/helpers/other/NotFoundCard";
import {fetchShipmentsListFx} from "../../models/issues";
import LocalizedDatePicker from "../../components/helpers/forms/LocalizedDatePicker";


export interface IShipment {
	_id: string;
	supplier: IUser;
	debtor: IUser;
	factor: IUser;
	shipmentCode: string;
	monetaryClaimHuman: string;
	firstPaymentHuman: string;
	agreementPaymentDate: string;
	contractPaymentDate: string;
	actShipmentDate: string;
	actNumber: string;
	contractNumber: string;
	contractDate: string;
	invoiceNumber?: string | undefined;
	invoiceDate?: string | undefined;
	createdAt: string;
	status: boolean;
}

const DocumentsPage = (): React.ReactElement => {
	const [shipments, setShipments] = useState<IShipment[]>([]);
	const [suppliers, setSuppliers] = useState([{id: null, name: 'Все'}]);
	const [debtors, setDebtors] = useState([{id: null, name: 'Все'}]);
	const [factors, setFactors] = useState([{id: null, name: 'Все'}]);

	const formik = useFormik({
		initialValues: {
			supplier: {id: null, name: 'Все'},
			supplierINN: '',
			debtor: {id: null, name: 'Все'},
			debtorINN: '',
			factor: {id: null, name: 'Все'},
			factorINN: '',
			dateStart: DateTime.now().startOf('month'),
			dateEnd: DateTime.now().endOf('month')
		},
		onSubmit: data => {

		},
	});

	function unique(array: any[], propertyName: string) {
		return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
	}

	const fetchFinancingIssues = async () => {
		const response = await fetchShipmentsListFx();
		setShipments(response);
		// @ts-ignore
		 setSuppliers([...unique(response.map(shipment => shipment.supplier), 'id'), {id: null, name: 'Все'}]);
		// @ts-ignore
		setDebtors([...unique(response.map(shipment => shipment.debtor), 'id'), {id: null, name: 'Все'}])
		// @ts-ignore
		setFactors([...unique(response.map(shipment => shipment.factor), 'id'), {id: null, name: 'Все'}])
	};

	useEffect(() => {
		const fetchPromises = [fetchFinancingIssues()];
		Promise.all(fetchPromises).then();
	}, []);
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<Box sx={{height: '100%'}}>
			<Stack sx={{mb: 3}}>
				<Typography variant='h2'>Поставки</Typography>
			</Stack>
			<>
				<Card sx={{m: '15px'}}>
					<CardContent>
						<FormikProvider value={formik}>
							<Form
								autoComplete='off'
								noValidate
								style={{height: '100%'}}
							>
								<Grid container spacing={2}>
									<Grid item xs={4}>
										<FormikAutocomplete
											textFieldProps={{label: 'Поставщик'}}
											defaultValue={{id: null, name: 'Все'}}
											getOptionLabel={option => option.name || ''}
											name='supplier'
											options={suppliers}/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											label='ИНН Поставщика'
											fullWidth
											{...formik.getFieldProps('supplierINN')}
											error={Boolean(formik.touched.supplierINN && formik.errors.supplierINN)}
											helperText={formik.touched.supplierINN && formik.errors.supplierINN}/>
									</Grid>
									<Grid item xs={4}>
										<FormikAutocomplete
											textFieldProps={{label: 'Дебитор'}}
											defaultValue={{id: null, name: 'Все'}}
											getOptionLabel={option => option.name || ''}
											name='debtor'
											options={debtors}/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											label='ИНН Дебитора'
											fullWidth
											{...formik.getFieldProps('debtorINN')}
											error={Boolean(formik.touched.debtorINN && formik.errors.debtorINN)}
											helperText={formik.touched.debtorINN && formik.errors.debtorINN}/>
									</Grid>
									<Grid item xs={4}>
										<FormikAutocomplete
											textFieldProps={{label: 'Фактор'}}
											defaultValue={{id: null, name: 'Все'}}
											getOptionLabel={option => option.name || ''}
											name='factor'
											options={factors}/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											label='ИНН Фактора'
											fullWidth
											{...formik.getFieldProps('factorINN')}
											error={Boolean(formik.touched.factorINN && formik.errors.factorINN)}
											helperText={formik.touched.factorINN && formik.errors.factorINN}/>
									</Grid>
									<Grid item xs={4}>
										<LocalizedDatePicker fullWidth name='dateStart' label='Создан от'/>
									</Grid>
									<Grid item xs={4}>
										<LocalizedDatePicker minDate={formik.values.dateStart} fullWidth
											name='dateEnd' label='Создан до'/>
									</Grid>
								</Grid>
							</Form>
						</FormikProvider>
					</CardContent>
				</Card>
				{!shipments?.length ? (
					<NotFoundCard name='Поставки'/>
				) : (
					<PaginationTable
						options={[
							{
								key: 'actNumber',
								title: 'Номер Акта',
								sx: {textAlign: 'center'},
								isPopover: true,
							},
							{
								key: 'actShipmentDate',
								title: 'Дата поставки по Акту',
								sx: {textAlign: 'center'},
								isPopover: true,
							},
							{
								key: 'agreementPaymentDate',
								title: 'Дата оплаты по Соглашению',
								sx: {textAlign: 'center'},
								isPopover: true,
							},
							{
								key: 'supplier',
								title: 'Поставщик',
								sx: {textAlign: 'center'},
								isPopover: true,
							},
							{
								key: 'debtor',
								title: 'Дебитор',
								sx: {textAlign: 'center'},
								isPopover: true,
							},
							{
								key: 'factor',
								title: 'Фактор',
								sx: {textAlign: 'center'},
								isPopover: true,
							},
							{
								key: 'createdAt',
								title: 'Дата создания',
								sx: {textAlign: 'center'},
								isPopover: true,
							}
						]}
						rows={shipments.filter(shipment =>
							(formik.values.supplier.id === shipment.supplier.id || formik.values.supplier.id === null)
							&& (formik.values.supplierINN.length === 0 || shipment.supplier.inn.includes(formik.values.supplierINN))
							&& (formik.values.debtorINN.length === 0 || shipment.debtor.inn.includes(formik.values.debtorINN))
							&& (formik.values.factorINN.length === 0 || shipment.factor.inn.includes(formik.values.factorINN))
							&& (formik.values.debtor.id === shipment.debtor.id || formik.values.debtor.id === null)
							&& (formik.values.factor.id === shipment.factor.id || formik.values.factor.id === null)
							&& (formik.values.dateStart === null || !formik.values.dateStart.isValid || DateTime.fromISO(shipment.createdAt).minus(formik.values.dateStart.toMillis()).toMillis() > 0)
							&& (formik.values.dateEnd === null || !formik.values.dateEnd.isValid || formik.values.dateEnd.minus(DateTime.fromISO(shipment.createdAt).toMillis()).toMillis() > 0)
						).map(shipment => ({
							...shipment,
							id: shipment._id,
							debtor: `${shipment.debtor.name} (${shipment.debtor.inn})`,
							supplier: `${shipment.supplier.name} (${shipment.supplier.inn})`,
							factor: `${shipment.factor.name} (${shipment.factor.inn})`,
							actShipmentDate: DateTime.fromISO(shipment.actShipmentDate).toFormat('dd.MM.yyyy'),
							agreementPaymentDate: DateTime.fromISO(shipment.agreementPaymentDate).toFormat('dd.MM.yyyy'),
							createdAt: DateTime.fromISO(shipment.createdAt).toFormat('dd.MM.yyyy')
						}))}
					/>)}
			</>
		</Box>
	);
};

export default DocumentsPage;

export {};