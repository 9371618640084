/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	sx?: SxProps;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, sx } = props;
	return value === index ? <Box sx={{ ...sx }}>{children}</Box> : null;
}
