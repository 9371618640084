/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import * as Yup from 'yup';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, TextField } from '@mui/material';
import { ICompanyCard, IPatchUserCompanyCardParams } from 'types';

type ICompanyCardEditFormValues = Pick<ICompanyCard,
	'name' | 'type' | 'site' | 'kpp' | 'ogrn' | 'directorFIO' | 'directorFIORP' | 'bankDetails' | 'locationAddress'>;

export const companyCardEditFormFields: Array<keyof ICompanyCardEditFormValues> = [
	'name', 'type', 'site', 'kpp', 'ogrn', 'directorFIO', 'directorFIORP', 'bankDetails', 'locationAddress'
];

export const companyCardEditFormFieldsTranslate = {
	name: 'Наименование компании в карточке',
	type: 'Правовая форма организации',
	site: 'Сайт',
	kpp: 'КПП',
	ogrn: 'ОГРН',
	directorFIO: 'ФИО директора',
	directorFIORP: 'ФИО директора в родительном падеже',
	bankDetails: 'Банковские реквизиты',
	locationAddress: 'Адрес',
};

export const helperTexts = {
	name: 'Без правовой формы, например Рога и Копыта',
	type: 'Например ООО'
}

interface ICompanyCardEditFormProps {
	companyCard: ICompanyCard;
	submitFunc: (values: IPatchUserCompanyCardParams) => void;
	disabled: boolean;
}

const CompanyCardEditForm = forwardRef(
	({ companyCard, submitFunc, disabled = false }: ICompanyCardEditFormProps, ref) => {
		const CompanyCardEditFormSchema = Yup.object().shape({
			name: Yup.string().required('Поле обязательно к заполнению'),
			type: Yup.string().required('Поле обязательно к заполнению'),
			site: Yup.string(),
			kpp: Yup.string().required('Поле обязательно к заполнению'),
			ogrn: Yup.string().required('Поле обязательно к заполнению'),
			directorFIO: Yup.string().required('Поле обязательно к заполнению'),
			directorFIORP: Yup.string().required('Поле обязательно к заполнению'),
			bankDetails: Yup.string(),
			locationAddress: Yup.string().required('Поле обязательно к заполнению'),
		});

		const formik = useFormik<ICompanyCardEditFormValues>({
			initialValues: {
				name: '',
				type: '',
				site: '',
				kpp: '',
				ogrn: '',
				directorFIO: '',
				directorFIORP: '',
				bankDetails: '',
				locationAddress: '',
			},
			validationSchema: CompanyCardEditFormSchema,
			onSubmit: data => submitFunc(data),
		});

		const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

		useImperativeHandle(ref, () => ({ ...formik }));

		const customSetFieldValue = <ICompanyCardKey extends keyof ICompanyCard>(
			property: ICompanyCardKey
		) => {
			if (companyCard[property]) setFieldValue(property, companyCard[property]);
		};

		useEffect(() => {
			companyCardEditFormFields.map(key => customSetFieldValue(key));
		}, [companyCard]);

		return (
			<FormikProvider value={formik}>
				<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
					<Stack spacing={2.5}>
						{companyCardEditFormFields
							.map(f => ({
								label: companyCardEditFormFieldsTranslate[f],
								field: f,
							}))
							.map(item => (
								<TextField
									key={item.field}
									fullWidth
									label={item.label}
									{...getFieldProps(item.field)}
									error={Boolean(touched[item.field] && errors[item.field])}
									//@ts-ignore
									helperText={(touched[item.field] && errors[item.field]) || helperTexts[item.field]}
									disabled={disabled}
								/>
							))}
					</Stack>
				</Form>
			</FormikProvider>
		);
	}
);

export default CompanyCardEditForm;
