/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {
	Card,
	CardContent,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { IconifyIcon } from '@iconify/react';
import { SxProps } from '@mui/system';
import TablePaginationActions from './TablePaginationActions';
import { GetIcon } from '../other/GetIcon';
import NamePopover from '../other/NamePopover';

interface IMinimalRow {
	id: string | number;
	[key: string]: any;
}

interface IOnClickAction {
	tooltip: string;
	onClick: (item: IMinimalRow) => void;
	icon: IconifyIcon;
}

interface IComponentAction {
	id: string;
	component: (props: any) => React.ReactElement;
	props?: {
		[key: string]: any;
	};
}

interface IPaginationTableProps {
	options: { key: string; title: string; sx?: SxProps; isPopover?: boolean }[];
	rows: IMinimalRow[];
	rowsActions?: (IOnClickAction | IComponentAction)[];
}

const transformRowValue = (value: any, isPopover: boolean | undefined) => {
	if (!value) return '-';
	if (isPopover) return <NamePopover name={String(Array.isArray(value) ? value[0] : value)} />;
	if (Array.isArray(value)) return String(value[0]);
	return String(value);
};

const PaginationTable = ({
	options,
	rows,
	rowsActions,
}: IPaginationTableProps): React.ReactElement => {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
		setPage(newPage);

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Card sx={{ m: 0 }}>
			<CardContent>
				<TableContainer>
					<Table stickyHeader sx={{ whiteSpace: 'nowrap' }}>
						<TableHead>
							<TableRow>
								{options.map(opt => (
									<TableCell key={opt.key} sx={{ ...opt.sx }}>
										<Typography variant='h5'>{opt.title}</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(rowsPerPage > 0
								? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								: rows
							).map(row => (
								<TableRow key={row.id}>
									{options.map(opt => (
										<TableCell key={String(opt.key)} sx={{ ...opt.sx }}>
											{opt.key === 'actions' && rowsActions ? (
												rowsActions.map(action => {
													if ('component' in action) {
														const Component = action.component;
														return (
															<Component
																key={action.id}
																row={row}
																{...action.props}
															/>
														);
													}
													return (
														<Tooltip
															key={action.tooltip}
															title={action.tooltip}
															placement='top'
															arrow
														>
															<IconButton
																color='inherit'
																onClick={() => action.onClick(row)}
															>
																{GetIcon(action.icon)}
															</IconButton>
														</Tooltip>
													);
												})
											) : (
												<Typography variant='h5'>
													{transformRowValue(row[opt.key], opt.isPopover)}
												</Typography>
											)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{rows.length > 5 && (
					<TablePagination
						component='div'
						rowsPerPageOptions={[5, 10, 25]}
						colSpan={6}
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default PaginationTable;
